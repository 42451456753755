export const ACTION_TYPES = {
  FETCHING_DATA: "FETCHING_DATA",
  FETCHING_SUCCESS: "FETCHING_SUCCESS"
};

const initialState = {
  numSiniestro: null,
  isFetching: false,
  timestamp: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCHING_DATA:
      return {
        ...state,
        isFetching: true
      };
    case ACTION_TYPES.FETCHING_SUCCESS:
      return {
        ...state,
        numSiniestro: action.numSiniestro,
        timestamp: action.timestamp,
        isFetching: false
      };
    default:
      return state;
  }
};
