import axios from 'axios';
import { URL_SESSION_CONTEXT } from '../../config/constants';
import { ACTION_TYPES } from './tracking.reducer';

export const precargarData = (numeroSiniestro, timestamp) => {
    return dispatch => {
        dispatch({type: ACTION_TYPES.PRELOAD_CONTEXT_REQUEST});
        obtenerDatosContextoService(numeroSiniestro, timestamp).then(response => {
            let formData = response.data.formData;
            dispatch({type: ACTION_TYPES.PRELOAD_CONTEXT_SUCCESS, siniestro: formData});
        }); // agregar catch
    }
}

export const limpiarDataPrecargada = () => {
    return dispatch => {
        dispatch({type: ACTION_TYPES.CLEAN_PRELOADED_DATA});
    }
}


export const obtenerDatosContextoService =  async (numeroSiniestro, timestamp) => {
    let params = {claimNumber: numeroSiniestro, timestamp: timestamp}
    return axios.get(URL_SESSION_CONTEXT, {params : params});
}
