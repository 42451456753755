import * as app from './app.json';
import * as ingreso from './ingreso.json';
import * as tracking from './tracking.json';
import * as shared from './shared.json';
import * as infoErrorPage from './infoErrorPage.json';
import * as workshopAdmission from './workshopAdmission.json';
import * as urls from './urls.json'

const es_aliro = {
  app,
  ingreso,
  tracking,
  shared,
  infoErrorPage,
  workshopAdmission,
  urls
};

export default es_aliro;
