import axios from 'axios';
import CONSTANTS, {URL_TRACKING} from '../../config/constants';
import history from '../../config/history';
import { ACTION_TYPES } from './workshopAdmission.reducer';

export const saveWorkshopAdmission = (noticeNumber, admissionDate) => {
	console.log('saveWorkshopAdmission 01');
	return dispatch => {
		console.log('saveWorkshopAdmission 02');
		saveWorkshopAdmissionActionService(noticeNumber, admissionDate)
			.then(response => {
				console.log('saveWorkshopAdmission.response', JSON.stringify(response));
				if (
					response.status === CONSTANTS.STATUS_CODE.OK
				) {
					dispatch(comunicarError({ codigo: '', mensaje: '' }));
					console.log('saveWorkshopAdmission push');
					history.push('/ingreso_taller/' + noticeNumber + '?request_response=200');
				} else {
					dispatch(
						comunicarError({
							codigo: response.data.status,
							mensaje: response.data.errorMessage
						})
					);
				}
			})
			.catch(error => {
				console.log('saveWorkshopAdmission.error', JSON.stringify(error));
				history.push('/error');
			});
	};
};

export const saveWorkshopAdmissionActionService = (noticeNumber, admissionDate) => {
	var url = URL_TRACKING + '/siniestros/' + noticeNumber + '/workshop-admission'
	console.log('axios.post ' + url);
	return axios.post(url,
		{
			admissionDate: admissionDate
		}
	);
};

export const setSiniestro = siniestro => {
	return {
		type: ACTION_TYPES.SAVE_WORKSHOP_ADMISSION_SUCCESS,
		siniestro: siniestro
	};
};

export const comunicarError = error => {
	return {
		type: ACTION_TYPES.SAVE_WORKSHOP_ADMISSION_FAILURE,
		error: error
	};
};
