import history from   '../../../../../config/history';
import CONSTANTS from '../../../../../config/constants';
import i18next from '../../../../../config/i18next';


export const changeToWorkshopAdmission = (numeroSiniestro, step,claimCategory) => {
    console.log('step.actions.chaneToWorkshopAdmission - numeroSiniestro:[], step: []', numeroSiniestro, step);
    return dispatch => {
        var url = '';
        switch (step) {
            case 'documentos':
                url = i18next.t('urls.trackingInfo.documentos.defaultLink');
                console.log('URL_BASE___________', url);    
                window.open(url, '_blank');
                break;
            case 'taller':
                history.push(`/ingreso_taller/${numeroSiniestro}`);
                break;
            case 'analisis':
                if(window.location.hostname.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0){
                    url = i18next.t('urls.trackingInfo.analisis.defaultLink');
                }else{
                    url = `https://www.liberty.cl/centro-de-siniestros/automotriz/procedimiento-de-siniestro#Procedimiento-siniestro-proceso-general`;
                }
                window.open(url, '_blank');
                break;
            case 'entrega':
            /*Se necesita que la pagina a mostrar cambie tambien dependiendo de la categoria del siniestro, el boton solo aparecerá 
            si es indemnizacion pendiente y perdida total*/
            if(claimCategory===CONSTANTS.CLAIM_TYPE.TOTAL){
                if(window.location.hostname.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0){
                    url = `https://www.aliroseguros.cl/procedimiento-de-siniestro`;
                }else{
                    url = `https://www.liberty.cl/centro-de-siniestros/automotriz/procedimiento-de-siniestro#Procedimiento-siniestro-perdida-total`;
                }
                
                window.open(url, '_blank');    
            }else{
                if(window.location.hostname.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0){
                    url = i18next.t('urls.trackingInfo.entrega.defaultLink');
                }else{
                    url = `https://www.liberty.cl/centro-de-siniestros/automotriz/procedimiento-de-siniestro#Procedimiento-siniestro-reparacion`;
                }          
                window.open(url, '_blank');    
            }    
            
                break;
            default: 
            // code block
        }
    };
};