import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import loggerMiddleware from 'redux-logger';
import reducer from '../shared/reducers';

const defaultMiddlewares = [thunkMiddleware];
const developMiddlewares = [loggerMiddleware];

let initialize = null;

if (process.env.NODE_ENV === 'development') {
  initialize = (initialState, middlewares = []) =>
    createStore(
      reducer,
      initialState,
      applyMiddleware(
        ...developMiddlewares,
        ...defaultMiddlewares,
        ...middlewares
      )
    );
} else {
  initialize = (initialState, middlewares = []) =>
   createStore(
      reducer,
      initialState,
      applyMiddleware(...defaultMiddlewares, ...middlewares)
    );
}

export default initialize;
