import i18next from '../../../../../config/i18next';
import CONSTANTS from '../../../../../config/constants';

class StepModel {
  constructor(
    step,
    status = null,
    date = null,
    claimNumber = null,
    type = null,
    substeps = [],
    label = null,
    helpText = null,
    buttonText = null,
    buttonLink = null
  ) {
    this.step = step;
    this.status = status;
    this.date = date;
    this.substeps = substeps;
    this.label = label;
    this.helpText = helpText;
    this.buttonText = buttonText;
    this.buttonLink = buttonLink;
    this.claimNumber = claimNumber;
    this.setType(type);
    this.setData();
    this.setImage();
  }
  step = null;
  status = null;
  date = null;
  image = null;
  altImage = null;
  substeps = null;
  label = null;
  helpText = null;
  buttonText = null;
  buttonLink = null;
  eventCategory = null;
  eventLabel = null;

  setType(categoriaSiniestro) {
    switch (categoriaSiniestro) {
      case CONSTANTS.CLAIM_TYPE.PARTIAL:
        this.type = 'partial';
        break;
      case CONSTANTS.CLAIM_TYPE.EXPRESS:
        this.type = 'express';
        break;
      case CONSTANTS.CLAIM_TYPE.THEFT:
        this.type = 'theft';
        break;
      case CONSTANTS.CLAIM_TYPE.TOTAL:
        this.type = 'total';
        break;
      case CONSTANTS.CLAIM_TYPE.THIRD:
        this.type = '';
        break;
      default:
        this.type = 'partial';
        break;
    }
  }

  setImage() {
    switch (this.step) {
      case 'ingreso':
        this.image = require('../../../../../../assets/icons/svg/denuncio.svg');
        this.altImage = 'denuncio';
        break;

      case 'documentos':
        this.image = require('../../../../../../assets/icons/svg/documentos.svg');
        this.altImage = 'documentos';
        break;

      case 'taller':
        this.image = require('../../../../../../assets/icons/svg/taller.svg');
        this.altImage = 'taller';
        this.buttonLink = this.buttonLink + this.claimNumber;
        break;

      case 'analisis':
        this.image = require('../../../../../../assets/icons/svg/reparacion.svg');
        this.altImage = 'reparacion';
        break;

      case 'entrega':
        this.image = require('../../../../../../assets/icons/svg/entrega.svg');
        this.altImage = 'entrega';
        break;

      default:
        break;
    }
  }

  setData() {
    this.label = i18next.t(
      'tracking.trackingInfo.' +
        this.step.toString() +
        '.' +
        this.type +
        '.' +
        this.status +
        '.label'
    );
    this.helpText = i18next.t(
      'tracking.trackingInfo.' +
        this.step.toString() +
        '.' +
        this.type +
        '.' +
        this.status +
        '.helpText'
    );
    this.buttonText = i18next.t(
      'tracking.trackingInfo.' +
        this.step.toString() +
        '.' +
        this.type +
        '.' +
        this.status +
        '.buttonText'
    );
    this.buttonLink = i18next.t(
      'tracking.trackingInfo.' +
        this.step.toString() +
        '.' +
        this.type +
        '.' +
        this.status +
        '.buttonLink'
    );
    this.eventCategory = i18next.t(
      'tracking.trackingInfo.' +
        this.step.toString() +
        '.' +
        this.type +
        '.' +
        this.status +
        '.eventCategory'
    );
    this.eventLabel = i18next.t(
      'tracking.trackingInfo.' +
        this.step.toString() +
        '.' +
        this.type +
        '.' +
        this.status +
        '.eventLabel'
    );
  }

  setSubsteps(substeps = []) {
    this.substeps = substeps;
  }

  setDate(date = null) {
    this.date = date;
    if (date) {
      this.status = 'approved';
      this.setData();
    }
  }

  setStatus(status = null) {
    this.status = status;
    this.setData();
  }
}

export default StepModel;
