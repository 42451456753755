import axios from 'axios';
import { ACTION_TYPES } from './ingreso.reducer';
import history from '../../config/history';
import CONSTANTS, { URL_TRACKING, PRODUCT_ALIRO, PRODUCT_ALIRO_2, BRAND_ERROR, URL_SESSION_CONTEXT, REDIRECT } from '../../config/constants';
import ReactGA from 'react-ga';

export const consultarSiniestro = numeroSiniestro => {
  return dispatch => {
    ReactGA.initialize('UA-47848886-21');
    dispatch({ type: ACTION_TYPES.CONSULTAR_SINIESTRO_REQUEST });
    consultarSiniestroActionService(numeroSiniestro)
      .then(response => {
        if (
          response.data.status === CONSTANTS.STATUS_CODE.OK &&
          response.data.responseBody.categoriaSiniestro !==
          CONSTANTS.CLAIM_TYPE.THIRD
        ) {
          if (verifyCompany(response.data.responseBody.codigoProducto)) {
            dispatch(setSiniestro(response.data.responseBody));
            dispatch(comunicarError({ codigo: '', mensaje: '' }));
            ReactGA.pageview(`/siniestro/${numeroSiniestro}`);
            history.push(`/siniestro/${numeroSiniestro}`);
          } else {
            dispatch(setSiniestro(response.data.responseBody));
            dispatch(
              comunicarError({
                codigo: BRAND_ERROR,
              })
            );
          }
        } else {
          dispatch(setSiniestro({}));
          dispatch(
            comunicarError({
              codigo: response.data.status,
              mensaje: response.data.errorMessage
            })
          );
        }
      })
      .catch(error => {
        dispatch(setSiniestro({}));
        history.push('/error');
      });
  };
};

export const consultarSiniestroDirecto = numeroSiniestro => {
  return dispatch => {
    ReactGA.initialize('UA-47848886-21');
    dispatch({ type: ACTION_TYPES.CONSULTAR_SINIESTRO_REQUEST });
    consultarSiniestroActionService(numeroSiniestro)
      .then(response => {
        dispatch(setSiniestro(response.data.responseBody));
        dispatch(
          comunicarError({
            codigo: BRAND_ERROR,
          })
        );
      })
      .catch(error => {
        dispatch(setSiniestro({}));
        history.push('/error');
      });
  };
};

export const salvarContexto = (timestamp, siniestro) => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.SALVAR_CONTEXTO });
    salvarContextoService(timestamp, siniestro).then(response => {
      console.log('seses');
      let redirectUrlString = siniestro.codigoProducto === PRODUCT_ALIRO || siniestro.codigoProducto === PRODUCT_ALIRO_2 ? REDIRECT.ALIRO : REDIRECT.LIBERTY
      let redirectUrl = new URL(redirectUrlString + `/siniestro/${siniestro.numeroSiniestro}`);

      let search_params = new URLSearchParams('');
		  search_params.append('timestamp', timestamp);

      redirectUrl.search =  search_params.toString();
      window.location.href = redirectUrl.toString();
    });
  }
}

export const consultarSiniestroActionService = async numeroSiniestro => {
  return axios.get(URL_TRACKING + '/siniestros/' + numeroSiniestro);
};

export const salvarContextoService = async (timestamp, siniestro) => {
  var postBody = {
    claimNumber: siniestro.numeroSiniestro,
    timestamp: timestamp,
    formData: siniestro
  }
  return axios.post(URL_SESSION_CONTEXT, postBody);
}

export const setSiniestro = siniestro => {
  return {
    type: ACTION_TYPES.CONSULTAR_SINIESTRO_SUCCESS,
    siniestro: siniestro
  };
};

export const comunicarError = error => {
  return {
    type: ACTION_TYPES.CONSULTAR_SINIESTRO_FAILURE,
    error: error
  };
};


export const verifyCompany = codigoProducto => {
  let urlName = window.location.hostname;
  let isAliro = urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0;
  if (isAliro) {
    return codigoProducto === PRODUCT_ALIRO || codigoProducto === PRODUCT_ALIRO_2;
  }
  return codigoProducto !== PRODUCT_ALIRO && codigoProducto !== PRODUCT_ALIRO_2;
}


