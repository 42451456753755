import React from 'react';
import styles from './substep.module.scss';
import PropTypes from 'prop-types';

const subStep = props => {
  let icon = null;
  switch (props.status) {
    case 'approved':
      icon = <span className={styles.icon + ' ' + styles.icon_approved} />;
      break;

    case 'pending':
      icon = <span className={styles.icon + ' ' + styles.icon_pending} />;
      break;

    case 'repairing':
      icon = <span className={styles.icon + ' ' + styles.icon_approved} />;
      break;

    case 'rejected':
      icon = <span className={styles.icon + ' ' + styles.icon_rejected} />;
      break;

    default:
      icon = <span className={styles.icon + ' ' + styles.icon_default} />;
      break;
  }

  return (
    <article className={styles.container}>
      {icon}
      <section className={styles.info}>
        <h3 className={styles.info_date}>{props.date}</h3>
        <label className={styles.info_label}>{props.label}</label>
      </section>
    </article>
  );
};

subStep.propTypes = {
  /** Propiedad que indica el estado de la subetapa. */
  status: PropTypes.string,
  /** Propiedad que indica la fecha de la subetapa. */
  date: PropTypes.string,
  /** Propiedad que indica el texto a mostrar en la subetapa. */
  label: PropTypes.string
};

export default subStep;
