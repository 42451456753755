import React, { Component } from "react";
import { connect } from "react-redux";
import history from "../../config/history";
import MainInfo from "./mainInfo/mainInfo";
import TrackingInfo from "./trackingInfo/trackingInfo";
import Button from "../../shared/button/button";
import styles from "./tracking.module.scss";
import i18next from "../../config/i18next";
import Modal from "../../shared/modal/modal";
import CONSTANTS from "../../config/constants";
import PropTypes from "prop-types";
import * as actions from "./tracking.actions";
import Loading from "../../shared/loading/loading";

class Tracking extends Component {
  constructor(props) {
    super(props);
    console.log("[tracking.js]: constructor");
    this.props = props;
    if (this.props.siniestro.numeroSiniestro === undefined) {
      var queryString = new URLSearchParams(props.location.search);
      var timestamp = queryString.get("timestamp");
      var numSiniestroPath = props.match.params.numSiniestro;

      if (numSiniestroPath && timestamp) {
        this.precargarDataAction(numSiniestroPath, timestamp);
        history.push(`/siniestro/${numSiniestroPath}`);
      } else {
        history.replace("/");
      }
    }
  }

  static propTypes = {
    /** Propiedad que tiene todos los datos del siniestro. */
    siniestro: PropTypes.object
  };

  state = {
    modalLiquidadorIsVisible: false
  };

  verifyAliro() {
    let urlName = window.location.hostname;

    if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {
      return true;
    }
    return false;
  }

  consultaSiniestroAuto = () => {
    console.log("CONSULTA SINIESTRO AUTO");
    window.open(
      "https://www.liberty.cl/centro-de-siniestros/automotriz/centro-de-ayuda-siniestro-de-auto",
      "_blank"
    );
  };

  precargarDataAction = (numeroSiniestro, timestamp) => {
    this.props.precargarData(numeroSiniestro, timestamp);
  };

  limpiarDataPrecargadaAction = () => {
    this.props.limpiarDataPrecargada();
  };

  innerHTMLTheftTextStrong = () => {
    return { __html: i18next.t("tracking.mensajeAdvertencia.theft.textTwo") };
  };

  componentDidMount() {
    if (this.props.siniestro.tipoLiquidador === "Oficial") {
      this.setState({
        modalLiquidadorIsVisible: true
      });
    } else {
      this.setState({
        modalLiquidadorIsVisible: false
      });
    }
  }

  toggleModal = () => {
    this.setState({
      modalLiquidadorIsVisible: false
    });
  };

  render() {
    let consult;
    localStorage.setItem("init", this.props.siniestro.fechaRegistroImplicado);
    if (!this.verifyAliro()) {
      console.log("not aliro");
      consult = (
        <section className={styles.consult_section}>
          <article className={styles.mainInfo_info_title}>
            <p className={styles.consult_content_text}>
              ¿Tienes dudas sobre tu siniestro?{" "}
            </p>
            <Button
              id="tracking_mainInfo_btn_volver_acceso"
              styles={styles.consult_button}
              buttonType="primary_yellow"
              onClick={this.consultaSiniestroAuto}
              type="button"
            >
              INGRESA AQUÍ
            </Button>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </article>
        </section>
      );
    }

    let modal;

    if (this.state.modalLiquidadorIsVisible) {
      modal = (
        <Modal
          isVisible={true}
          submitModal={this.toggleModal}
          btnModalCloseIsVisible={false}
          buttonProps={{
            buttonId: "liquidador_modal_button",
            submitButtonText: i18next.t("tracking.modalLiquidador.buttonText"),
            type: "button"
          }}
        >
          <section className={styles.modal_content}>
            <span className={styles.modal_content_icon + " icon-advertencia"} />
            <p className={styles.modal_content_text}>
              {i18next.t("tracking.modalLiquidador.modalText")}
            </p>
          </section>
        </Modal>
      );
    } else {
      modal = null;
    }

    let mensajeAdvertencia = null;
    switch (this.props.siniestro.categoriaSiniestro) {
      case CONSTANTS.CLAIM_TYPE.PARTIAL:
        if (
          this.props.siniestro.fechaPreAnalisis ||
          this.props.siniestro.codigoEstadoSiniestro ===
            CONSTANTS.CLAIM_STATUS.OPEN
        ) {
          mensajeAdvertencia = (
            <article className={styles.tracking_container_warning_message}>
              <p>{i18next.t("tracking.mensajeAdvertencia.partial")}</p>
            </article>
          );
        }
        break;
      case CONSTANTS.CLAIM_TYPE.THEFT:
        mensajeAdvertencia = (
          <article className={styles.tracking_container_theft_message}>
            <p className={styles.tracking_container_theft_message_title}>
              {i18next.t("tracking.mensajeAdvertencia.theft.title")}
            </p>
            <ol className={styles.tracking_container_theft_message_list}>
              <li className={styles.tracking_container_theft_message_list_text}>
                {i18next.t("tracking.mensajeAdvertencia.theft.textOne")}
              </li>
              <li
                className={styles.tracking_container_theft_message_list_text}
                dangerouslySetInnerHTML={this.innerHTMLTheftTextStrong()}
              />
            </ol>
          </article>
        );
        break;
      case CONSTANTS.CLAIM_TYPE.TOTAL:
        mensajeAdvertencia = (
          <article className={styles.tracking_container_warning_message}>
            <p>{i18next.t("tracking.mensajeAdvertencia.total")}</p>
          </article>
        );
        break;
      default:
        break;
    }

    let mensajeAdvertenciaRespuestos = null;
    if (
      this.props.siniestro.categoriaSiniestro === CONSTANTS.CLAIM_TYPE.PARTIAL
    ) {
      if (this.props.siniestro.fechaOrdenDeRepuestos) {
        mensajeAdvertenciaRespuestos = (
          <article className={styles.tracking_container_warning_message}>
            <p>{i18next.t("tracking.mensajeAdvertenciaRepuestos.partial")}</p>
          </article>
        );
      }
    }
    if (
      this.props.siniestro.codigoEstadoSiniestro ===
      CONSTANTS.CLAIM_STATUS.REJECTED
    ) {
      mensajeAdvertencia = (
        <section className={styles.tracking_container_rejected_message}>
          <span className={styles.tracking_container_rejected_message_icon} />
          <p className={styles.tracking_container_rejected_message_text}>
            {i18next.t("tracking.mensajeAdvertencia.rejected")}
          </p>
        </section>
      );
    }

    let newDate = this.props.location.state
      ? this.props.location.state.nuevaFecha
      : null;
    return this.props.isFetching ? (
      <Loading />
    ) : (
      <section className={styles.tracking_container}>
        {modal}
        <MainInfo
          numSiniestro={this.props.siniestro.numeroSiniestro}
          vehiculoPatente={this.props.siniestro.patente}
          vehiculoMarca={this.props.siniestro.marca}
          vehiculoModelo={this.props.siniestro.modelo}
          tallerNombre={this.props.siniestro.tallerImplicado}
          tallerDireccion={this.props.siniestro.direccionTaller}
          tallerTelefono={this.props.siniestro.telefonoTaller}
          email={this.props.siniestro.emailTaller}
          expandWorkshopId="tracking_maininfo_expandir_taller"
          categoriaSiniestro={this.props.siniestro.categoriaSiniestro}
          nombreLiquidador={this.props.siniestro.nombreLiquidador}
          telefonoLiquidador={this.props.siniestro.telefonoLiquidador}
          tipoLiquidador={this.props.siniestro.tipoLiquidador}
          onBackClick={this.limpiarDataPrecargadaAction}
        />
        <TrackingInfo
          siniestro={
            newDate
              ? { ...this.props.siniestro, fechaIngresoATaller: newDate }
              : this.props.siniestro
          }
        />
        {consult}
        {mensajeAdvertencia}
        {mensajeAdvertenciaRespuestos}
      </section>
    );
  }
}
/*
 * state.dataPrecargada solo se utiliza cuando se llega desde un cambio de contexto aliro/liberty
 * para el resto de los casos el siniestro llega desde el reducer de ingreso (ingreso.reducer.js)
 */
const mapStateToProps = state => {
  return {
    siniestro: state.dataPrecargada.siniestro
      ? state.dataPrecargada.siniestro
      : state.siniestro.siniestro,
    isFetching: state.dataPrecargada.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  precargarData: (numeroSiniestro, timestamp) =>
    dispatch(actions.precargarData(numeroSiniestro, timestamp)),
  limpiarDataPrecargada: () => dispatch(actions.limpiarDataPrecargada())
});

export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
