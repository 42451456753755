import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import "./materialPicker.css";
import moment from 'moment';
import 'moment/locale/es'
import MomentUtils from '@date-io/moment';
import ImagenCalendario from "../../../assets/icons/svg/calendar.svg";

export default function MaterialPicker(props) {
    const [selectedDate, handleDateChange] = useState(null);
    let tmpDate = localStorage.getItem('init');
    let parts = tmpDate.split('/');
    let fechaInicial = parts[2] + '-' + parts[1] + '-' + parts[0];
    const [open, setOpen] = useState(false);
    console.log(moment(selectedDate).format('L'));
    const onChange = (value) => {
        handleDateChange(value);
        let tmpfecha = new Date(value);
        console.log(tmpfecha);
        props.notifyChangeDate(tmpfecha); 
	}
    
    return (
            <MuiPickersUtilsProvider 
                utils={MomentUtils} 
                locale={moment.locale('es', {
                months: 'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
                monthsShort: 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
                weekdays: 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
                weekdaysShort: 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_'),
                weekdaysMin: 'Do_Lu_Ma_Mi_Ju_Vi_Sa'.split('_'),})}
                value={selectedDate}
            >
                <KeyboardDatePicker
                    name="ReactDatepicker"
                    disableFuture={true}
                    allowKeyboardControl={false}
                    inputVariant="filled"
                    error={false}
                    variant="dialog"
                    cancelLabel="Cancelar"
                    okLabel="Ok"
                    label="Busca por Fecha"
                    format="DD/MMM/yyyy"
                    minDate={moment(fechaInicial)}
                    value={selectedDate}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    TextFieldComponent={(prop) => (
                        <TextField {...prop} onClick={(e) => setOpen(true)} />
                    )}
                    onChange={date => {onChange(date)}}
                    InputProps={{
                        readOnly: true,
                        disableUnderline: true
                    }}
                    keyboardIcon={<img src={ImagenCalendario} alt="calendar" width="24px" height="24px" />}
                    minDateMessage="No es posible seleccionar una fecha anterior a la fecha de denuncio"
                    maxDateMessage="No es posible seleccionar una fecha superior al día de hoy"
                />
            </MuiPickersUtilsProvider>
    );
}

