import React, { Component } from 'react';
import Button from '../../../shared/button/button';
import Input from '../../../shared/input/input';
import SwitchSelector from '../../../shared/switchSelector/switchSelector';
import { ITEMS } from '../../../config/constants';
import styles from './form.module.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';



class Form extends Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this._reCaptchaRef = React.createRef();

    if (this.props.isFromQR) {
      this.items = []
      this.state = {
        selected: ITEMS.SPARE_PARTS.id
      }
    } else {
      this.items = [
        ITEMS.INSURED,
        ITEMS.THIRD
      ]
      this.state = {
        selected: ITEMS.INSURED.id
      }
    }
    console.log("isFromQR:", this.props.isFromQR)
  }

  verifyAliro() {
    let urlName = window.location.hostname;

    if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {
      return true;
    }
    return false;
  }

  static propTypes = {
    /** Propiedad de función que se acciona para cambiar el estado del recaptcha. */
    changeReCaptchaStatus: PropTypes.func,
    /** Propiedad que indica el texto del título del formulario. */
    title: PropTypes.string,
    /** Propiedad que indica el texto del subtítulo del formulario. */
    subtitle: PropTypes.string,
    /** Propiedad que indica el id del switch */
    switchId: PropTypes.string,
    /** Propiedad que indica el id del input. */
    inputId: PropTypes.string,
    /** Propiedad que indica el placeholder del input. */
    placeholder: PropTypes.string,
    /** Propiedad que indica el numero de siniestro. */
    numSiniestro: PropTypes.string,
    /** Propiedad que indica el numero de guia. */
    numGuia: PropTypes.string,
    /** Propiedad de función que se acciona cuando se pierde el foco del input. */
    onInputBlur: PropTypes.func,
    /** Propiedad de función que se acciona cuando se hace foco en el input. */
    onInputFocus: PropTypes.func,
    /** Propiedad que indica si el error del formulario se muestra. */
    showError: PropTypes.bool,
    /** Propiedad de función que se acciona cuando el valor del input cambia. */
    onInputChange: PropTypes.func,
    /** Propiedad que indica el texto que tendrá el tooltip. */
    tooltipMessage: PropTypes.string,
    /** Propiedad que indica el id del tooltip. */
    infoId: PropTypes.string,
    /** Propiedad de objeto contenedor de los estilos del recaptcha, estos estilos vienen del componenete padre. */
    recaptchaStyle: PropTypes.string,
    /** Propiedad indica el id que tendrá el boton de tercero en el switch. */
    insuredButtonId: PropTypes.string,
    /** Propiedad de función que se acciona cuando se presiona el boton tercero en el switch. */
    onInsuredSubmit: PropTypes.func,
    /** Propiedad de función que se acciona cuando se presiona el boton taller en el switch. */
    onWorkshopSubmit: PropTypes.func,
    /** Propiedad que indica el texto de terceros. */
    thirdText: PropTypes.string,
    /** Propiedad que indica el id que tendrá el boton de terceros. */
    thirdButtonId: PropTypes.string,
    /** Propiedad que indica el texto que tendrá el boton de terceros. */
    thirdButtonText: PropTypes.string,
    /** Propiedad de función que se acciona cuando se presiona el boton de terceros. */
    onThirdSubmit: PropTypes.func,
    /** Propiedad que indica el link del botón de terceros. */
    thirdButtonLink: PropTypes.string,
    /** Propiedad que indica si la URL es accedida mediante el codigo QR de
    * recepcion de repuestos. */
    isFromQR: PropTypes.bool
  };

  state = {
    reCaptchaValue: '',
    reCaptchaLoad: false,
    reCaptchaExpired: false,
    selected: 0
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ reCaptchaLoad: true });
    }, process.env.REACT_APP_RECAPTCHA_DELAY);
  }

  handleChange = value => {
    this.setState({
      reCaptchaValue: value,
      reCaptchaLoad: true,
      reCaptchaExpired: false
    });
    this.reCaptchaIsValid();
  };

  handleExpired = () => {
    this.setState({
      reCaptchaExpired: true,
      reCaptchaValue: '',
      reCaptchaLoad: true
    });
    this.reCaptchaIsValid();
  };

  reCaptchaIsValid = () => {
    if (this.state.reCaptchaValue !== '' && !this.state.reCaptchaExpired) {
      this.props.changeReCaptchaStatus(true);
    } else {
      this.props.changeReCaptchaStatus(false);
    }
  };

  onSwitchChangeHandler = (index, option, direction) => {
    let styleMov = undefined;
    if (direction < 0) {
      styleMov = styles.form_consulta_siniestro_cnt_move_left;
    } else {
      styleMov = styles.form_consulta_siniestro_cnt_move_right;
    }

    this.setState(prevState => ({
      selected: index,
      styleMov: styleMov
    }));
  };

  render() {
    let styles_form_asegurado = '';
    let styles_form_tercero = '';
    let styles_form_taller = '';

    if (this.state.selected === ITEMS.THIRD.id) {
      styles_form_tercero = styles.form_consulta_siniestro_cnt_tercero;

      styles_form_asegurado = [
        styles.form_consulta_siniestro_cnt_asegurado,
        styles.form_consulta_siniestro_cnt_hidden,
        //this.state.styleMov
      ].join(' ');
      styles_form_taller = [
        styles.form_consulta_siniestro_cnt_taller,
        styles.form_consulta_siniestro_cnt_hidden,
        //this.state.styleMov
      ].join(' ');

    } else if (this.state.selected === ITEMS.INSURED.id) {
      styles_form_asegurado = styles.form_consulta_siniestro_cnt_asegurado;

      styles_form_tercero = [
        styles.form_consulta_siniestro_cnt_tercero,
        styles.form_consulta_siniestro_cnt_hidden,
        //this.state.styleMov
      ].join(' ');

      styles_form_taller = [
        styles.form_consulta_siniestro_cnt_taller,
        styles.form_consulta_siniestro_cnt_hidden,
        //this.state.styleMov
      ].join(' ');

    } else if (this.state.selected === ITEMS.SPARE_PARTS.id) {
      styles_form_taller = styles.form_consulta_siniestro_cnt_taller;

      styles_form_asegurado = [
        styles.form_consulta_siniestro_cnt_asegurado,
        styles.form_consulta_siniestro_cnt_hidden,
        //this.state.styleMov
      ].join(' ');
      styles_form_tercero = [
        styles.form_consulta_siniestro_cnt_tercero,
        styles.form_consulta_siniestro_cnt_hidden,
        //this.state.styleMov
      ].join(' ');
    }
    return (
      <form className={styles.form_consulta_siniestro}>
        <h2
          className={styles.form_consulta_siniestro_title}
          id="ingreso_form_title"
        >
          {this.props.title}
        </h2>
        {!this.verifyAliro() ?
          <div>
            <p
              className={styles.form_consulta_siniestro_subtitle}
              id="ingreso_form_subtitle"
            >
              {this.props.subtitle}
            </p>
            <SwitchSelector
              styles={styles.form_consulta_siniestro_switch}
              options={this.items}
              id={this.props.switchId}
              initialSelection={this.state.selected}
              onChange={this.onSwitchChangeHandler}
            />
          </div> : null}
        <section className={styles.form_consulta_siniestro_cnt}>
          <section className={styles_form_asegurado}>
            <Input
              id={this.props.inputId}
              placeholder={this.props.placeholder}
              errorMessage={this.props.errorMessage}
              styles={styles.form_consulta_siniestro_cnt_asegurado_input}
              value={this.props.numSiniestro}
              minLength={7}
              maxLength={10}
              onBlur={this.props.onInputBlur}
              onFocus={this.props.onInputFocus}
              showError={this.props.showError}
              onChange={this.props.onInputChange}
              ref={this.inputRef}
              tooltipMessage={this.props.tooltipMessage}
              infoId={this.props.infoId}
            />
            <div className={styles.recaptcha}>
              {this.state.reCaptchaLoad && (
                <ReCAPTCHA
                  className={this.props.recaptchaStyle}
                  theme="light"
                  size="normal"
                  ref={this._reCaptchaRef}
                  sitekey={this.verifyAliro() ? process.env.REACT_APP_RECAPTCHA_API_KEY_ALIRO : process.env.REACT_APP_RECAPTCHA_API_KEY}
                  onChange={this.handleChange}
                  onExpired={this.handleExpired}
                />
              )}
            </div>
            <Button
              id={this.props.insuredButtonId}
              texto={this.props.insuredButtonText}
              styles={styles.form_consulta_siniestro_cnt_asegurado_button}
              buttonType="primary_yellow"
              onClick={this.props.onInsuredSubmit}
              type="submit"
            />
          </section>
          <section className={styles_form_tercero}>
            <p className={styles.form_consulta_siniestro_cnt_tercero_text}>
              {this.props.thirdText}
            </p>
            <Button
              id={this.props.thirdButtonId}
              texto={this.props.thirdButtonText}
              styles={styles.form_consulta_siniestro_asegurado_button}
              buttonType="primary_yellow"
              onClick={this.props.onThirdSubmit}
              type="link"
              href={this.props.thirdButtonLink}
            />
          </section>
          <section className={styles_form_taller}>
            <Input
              id={this.props.inputId}
              placeholder={this.props.placeholder}
              required={true}
              errorMessage={this.props.errorMessage}
              styles={styles.form_consulta_siniestro_cnt_asegurado_input}
              value={this.props.numSiniestro}
              labelTitle={this.props.label}
              minLength={7}
              maxLength={10}
              onBlur={this.props.onInputBlur}
              onFocus={this.props.onInputFocus}
              showError={this.props.showError}
              onChange={this.props.onInputChange}
              ref={this.inputRef}
              infoId={this.props.infoId}
            />
            <Input
              id={this.props.inputId}
              placeholder={this.props.guideNumberPlaceHolder}
              required={true}
              errorMessage={this.props.errorMessageGuideNumber}
              styles={styles.form_consulta_siniestro_cnt_asegurado_input}
              value={this.props.numGuia}
              labelTitle={this.props.guideNumberLabel}
              minLength={7}
              maxLength={10}
              onBlur={this.props.onInputBlur}
              onFocus={this.props.onInputFocus}
              showError={this.props.showErrorGuideNumber}
              onChange={this.props.onInputChangeGuideNumber}
              ref={this.inputRef}
              infoId={this.props.infoId}
            />
            <div className={styles.recaptcha}>
              {this.state.reCaptchaLoad && (
                <ReCAPTCHA
                  className={this.props.recaptchaStyle}
                  theme="light"
                  size="normal"
                  ref={this._reCaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_API_KEY}
                  onChange={this.handleChange}
                  onExpired={this.handleExpired}
                />
              )}
            </div>
            <Button
              id={this.props.insuredButtonId}
              texto={this.props.insuredButtonText}
              styles={styles.form_consulta_siniestro_cnt_asegurado_button}
              buttonType="primary_yellow"
              onClick={this.props.onWorkshopSubmit}
            />
          </section>
        </section>
      </form>
    );
  }
}

export default Form;
