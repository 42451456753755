import React, { Component } from 'react';
import i18next from '../../../config/i18next';
import Button from '../../../shared/button/button';
import styles from './mainInfo.module.scss';
import history from '../../../config/history';
import CONSTANTS from '../../../config/constants';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

class MainInfo extends Component {
  gtag = window.gtag;
  state = {
    containerInfoStyles: styles.container_info,
    buttonContent: i18next.t('tracking.mainInfo.expandButton')
  };
  constructor(props) {
    super();
    this.props = props;
    this.containerInfoArray = [styles.container_info];
    this.mainInfoRef = React.createRef();
    this.adjusterInfoRef = React.createRef();
    this.workshopInfoRef = React.createRef();
    this.showMoreInfo = false;
  }

  static propTypes = {
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    tipoLiquidador: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    numSiniestro: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    vehiculoPatente: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    vehiculoMarca: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    nombreLiquidador: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    telefonoLiquidador: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    categoriaSiniestro: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    tallerNombre: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    tallerDireccion: PropTypes.string,
    /** Propiedad que indica el valor a mostrar en pantalla según la plantilla de visualización. */
    tallerTelefono: PropTypes.string,
    email: PropTypes.string,
    /** Propiedad que indica el id del botón mostrar información del taller en mobile. */
    expandWorkshopId: PropTypes.string,
    /** Función que se ejecuta cuando se presiona el botón 'volver a consultar'**/
    onBackClick:  PropTypes.func
  };


  componentDidMount() {
    this.checkSize();
    window.addEventListener('resize', this.checkSize);
  }

  toggleWorkshop = () => {
    this.showMoreInfo = !this.showMoreInfo;
    if (this.showMoreInfo) {
      this.setState({
        buttonContent: i18next.t('tracking.mainInfo.expandButtonExpanded')
      });
      this.mainInfoRef.current.style.height =
        this.mainInfoRef.current.scrollHeight + 'px';
    } else {
      this.setState({
        buttonContent: i18next.t('tracking.mainInfo.expandButton')
      });
      this.mainInfoRef.current.style.height = '100px';
    }
  };

  checkReferences = () => {
    return this.mainInfoRef.current;
  };

  checkSize = () => {
    if (this.checkReferences()) {
      if (window.innerWidth >= CONSTANTS.WINDOW_SIZES.TABLET) {
        this.mainInfoRef.current.style.minHeight =
          this.mainInfoRef.current.scrollHeight + 'px';
      } else {
        console.log(
          'this.mainInfoRef.current.children.length: ',
          this.mainInfoRef.current.children.length
        );
        if (this.props.tipoLiquidador === CONSTANTS.ADJUSTER_TYPE.OFICIAL) {
          this.mainInfoRef.current.style.minHeight =
            this.mainInfoRef.current.children[1].offsetTop +
            this.mainInfoRef.current.children[1].scrollHeight -
            this.mainInfoRef.current.children[0].offsetTop +
            'px';
        } else {
          console.log('Tengo 2 hijos');
          this.mainInfoRef.current.style.minHeight =
            this.mainInfoRef.current.children[0].offsetHeight + 'px';
        }
      }
    }
  };

  onBackClick = () => {
    ReactGA.event({
      category: 'busqueda',
      action: 'click',
      label: '2da_consulta_siniestro',
    });
    history.replace('/');
    if(this.props.onBackClick){
      this.props.onBackClick();
    }
  };

  render() {
    return (
      <section className={styles.container}>
        <section className={styles.claim_info}>
          <h2 className={styles.claim_info_title}>
            {i18next.t('tracking.mainInfo.claimInfo.title')}
          </h2>
          <Button
            id="tracking_mainInfo_btn_volver_acceso"
            styles={styles.claim_info_btn_exit}
            buttonType="primary_yellow"
            onClick={this.onBackClick}
            type="button"
          >
            <span className={styles.icon + ' icon-salir'} />
          </Button>
          <p className={styles.claim_info_text}>
            {i18next.t('tracking.mainInfo.claimInfo.text')}
            <strong
              id="tracking_mainInfo_numeroSiniestro"
              className={styles.claim_info_text_strong}
            >
              {this.props.numSiniestro ||
                i18next.t('tracking.mainInfo.withoutInfo')}
            </strong>
          </p>
        </section>
        <section
          ref={this.mainInfoRef}
          className={this.state.containerInfoStyles}
        >
          <section className={styles.info}>
            <h3 className={styles.info_title}>
              {i18next.t('tracking.mainInfo.vehicleInfo.title')}
            </h3>
            <ul className={styles.info_list}>
              <li className={styles.info_list_item}>
                <div className={styles.info_list_item_title}>
                  {i18next.t(
                    'tracking.mainInfo.vehicleInfo.listItemTitlePatent'
                  )}
                </div>
                <div
                  id="tracking_mainInfo_vehiculoPatente"
                  className={styles.info_list_item_info}
                >
                  {this.props.vehiculoPatente ||
                    i18next.t('tracking.mainInfo.withoutInfo')}
                </div>
              </li>
              <li className={styles.info_list_item}>
                <div className={styles.info_list_item_title}>
                  {i18next.t(
                    'tracking.mainInfo.vehicleInfo.listItemTitleBrand'
                  )}
                </div>
                <div
                  id="tracking_mainInfo_vehiculoMarca"
                  className={styles.info_list_item_info}
                >
                  {this.props.vehiculoMarca ||
                    i18next.t('tracking.mainInfo.withoutInfo')}
                </div>
              </li>
              <li className={styles.info_list_item}>
                <div className={styles.info_list_item_title}>
                  {i18next.t(
                    'tracking.mainInfo.vehicleInfo.listItemTitleModel'
                  )}
                </div>
                <div
                  id="tracking_mainInfo_vehiculoModelo"
                  className={styles.info_list_item_info}
                >
                  {this.props.vehiculoModelo ||
                    i18next.t('tracking.mainInfo.withoutInfo')}
                </div>
              </li>
            </ul>
          </section>
          {this.props.tipoLiquidador === CONSTANTS.ADJUSTER_TYPE.OFICIAL ? (
            <section className={styles.info}>
              <h3 className={styles.info_title}>
                {i18next.t('tracking.mainInfo.adjusterInfo.title')}
              </h3>
              <ul className={styles.info_list}>
                <li className={styles.info_list_item}>
                  <div className={styles.info_list_item_title}>
                    {i18next.t(
                      'tracking.mainInfo.adjusterInfo.listItemTitleName'
                    )}
                  </div>
                  <div
                    id="tracking_mainInfo_nombreLiquidador"
                    className={styles.info_list_item_info}
                  >
                    {this.props.nombreLiquidador ||
                      i18next.t('tracking.mainInfo.withoutInfo')}
                  </div>
                </li>
                <li className={styles.info_list_item}>
                  <div className={styles.info_list_item_title}>
                    {i18next.t(
                      'tracking.mainInfo.adjusterInfo.listItemTitlePhone'
                    )}
                  </div>
                  <div
                    id="tracking_mainInfo_telefonoLiquidador"
                    className={styles.info_list_item_info}
                  >
                    {this.props.telefonoLiquidador ||
                      i18next.t('tracking.mainInfo.withoutInfo')}
                  </div>
                </li>
              </ul>
            </section>
          ) : null}
          {this.props.categoriaSiniestro !== CONSTANTS.CLAIM_TYPE.THEFT && this.props.categoriaSiniestro !== CONSTANTS.CLAIM_TYPE.TOTAL ? (
            <section className={styles.info} ref={this.workshopInfoRef}>
              <h3 className={styles.info_title}>
                {i18next.t('tracking.mainInfo.workshopInfo.title')}
              </h3>
              <ul className={styles.info_list}>
                <li className={styles.info_list_item}>
                  <div className={styles.info_list_item_title}>
                    {i18next.t(
                      'tracking.mainInfo.workshopInfo.listItemTitleName'
                    )}
                  </div>
                  <div
                    id="tracking_mainInfo_tallerNombre"
                    className={styles.info_list_item_info}
                  >
                    {this.props.tallerNombre ||
                      i18next.t('tracking.mainInfo.withoutInfo')}
                  </div>
                </li>
                <li className={styles.info_list_item}>
                  <div className={styles.info_list_item_title}>
                    {i18next.t(
                      'tracking.mainInfo.workshopInfo.listItemTitleAddress'
                    )}
                  </div>
                  <div
                    id="tracking_mainInfo_tallerDireccion"
                    className={styles.info_list_item_info}
                  >
                    {this.props.tallerDireccion ||
                      i18next.t('tracking.mainInfo.withoutInfo')}
                  </div>
                </li>
                <li className={styles.info_list_item}>
                  <div className={styles.info_list_item_title}>
                    {i18next.t(
                      'tracking.mainInfo.workshopInfo.listItemTitlePhone'
                    )}
                  </div>
                  <div
                    id="tracking_mainInfo_tallerTelefono"
                    className={styles.info_list_item_info}
                  >
                    {this.props.tallerTelefono ||
                      i18next.t('tracking.mainInfo.withoutInfo')}
                  </div>
                </li>
                <li className={styles.info_list_item}>
                  <div className={styles.info_list_item_title}>
                    {i18next.t(
                      'tracking.mainInfo.workshopInfo.email'
                    )}
                  </div>
                  <div
                    id="tracking_mainInfo_email"
                    className={styles.info_list_item_info}
                  >
                    {this.props.email ||
                      i18next.t('tracking.mainInfo.withoutInfo')}
                  </div>
                </li>
              </ul>
            </section>
          ) : null}
        </section>
        {this.props.categoriaSiniestro !== CONSTANTS.CLAIM_TYPE.THEFT && this.props.categoriaSiniestro !== CONSTANTS.CLAIM_TYPE.TOTAL ? (
          <Button
            id={this.props.expandWorkshopId}
            texto={this.state.buttonContent}
            styles={styles.expand_container}
            buttonType="primary_yellow"
            onClick={this.toggleWorkshop}
            type="button"
          />
        ) : null}
      </section>
    );
  }
}

export default MainInfo;
