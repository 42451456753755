import { combineReducers } from "redux";
import ingresoReducer from "../../modules/ingreso/ingreso.reducer";
import sparePartsReducer from "../../modules/spareParts/spareParts.reducer";
import globalReducer from "../../App.reducer";
import trackingReducer from "../../modules/tracking/tracking.reducer";
import callbackReducer from "../../modules/callback/callback.reducer";

const rootReducer = combineReducers({
  global: globalReducer,
  siniestro: ingresoReducer,
  spareParts: sparePartsReducer,
  dataPrecargada: trackingReducer,
  callback: callbackReducer,
});

export default rootReducer;
