/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import i18next from '../../config/i18next';
import './brandHeader.scss';
import Media from "react-media";

class BrandHeader extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.navRef = React.createRef();
  }



  toggleMenu = () => {
    if (this.checkReferences) {
      this.navRef.current.classList.toggle('nav--expanded');
      const submenus = this.navRef.current.querySelectorAll('.nav-item');
      for (let submenu of submenus) {
        submenu.classList.remove('nav-item--expanded');
      }
    }
  };

  verifyAliro = () => {
    let urlName = window.location.hostname;

    if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {
      return true;
    }
    return false;
  }

  checkReferences = () => {
    return this.navRef.current;
  };

  navItemClickHandler = event => {
    const { currentTarget } = event;
    event.preventDefault();
    if (this.checkReferences) {
      const submenus = this.navRef.current.querySelectorAll('.nav-item');
      for (let submenu of submenus) {
        if (submenu !== currentTarget.parentElement) {
          submenu.classList.remove('nav-item--expanded');
        }
        currentTarget.parentElement.classList.toggle('nav-item--expanded');
      }
    }
  };

  render() {
    let {
      header,
      imageSrc,
      secondImageSrc,
      //name,
      subHeader,
      //lateralNavBar,
      imageWidth,
      imageHeight,
      imageHeightQuery,
      imageWidthQuery,
      secondImageWidth,
      secondImageHeight,
      secondImageHeightQuery,
      secondImageWidthQuery,
    } = this.props.brand.style;
    let {
      background,
      //height,
      navList,
      //width,
      paddingHeader,
      brandHref,
      brandAlt
    } = header;
    const logo = require(`../../../assets/img/${imageSrc}`);
    const logo2x = require(`../../../assets/img/${imageSrc}`);
    const secondLogo = require(`../../../assets/img/${secondImageSrc}`);

    return (
      <>
        <section className="header_cnt" style={{ background: background, paddingTop: "0px", paddingBottom: "6px" }}>
          <header className="header" style={{ padding: paddingHeader }}>
            <figure className="header-logo">
              <a href={brandHref}>
                <Media query="(max-width: 768px)">
                  {matches =>
                    matches ?
                      (
                        <img
                          style={{ width: imageHeightQuery, height: imageWidthQuery }}
                          className="img"
                          alt={brandAlt}
                          src={logo}
                          srcSet={logo2x}
                        />
                      )
                      :
                      (
                        <img
                          style={{ width: imageWidth, height: imageHeight }}
                          className="img"
                          alt={brandAlt}
                          src={logo}
                          srcSet={logo2x}
                        />
                      )
                  }
                </Media>
              </a>
            </figure>
            <span
              className="header-menu icon-burguermenu"
              onClick={event => this.toggleMenu()}
            />
            <ul className="header-prenav">
              {navList.length > 0 ? navList.map(function (item, i) {
                return (

                  <li key={i} className="item">
                    <a
                      className="item-link"
                      href={item.link}
                    >
                      {item.name}
                    </a>
                  </li>
                )
              }) :
                <figure className="header-logo">
                  <Media query="(max-width: 768px)">
                    {matches =>
                      matches ?
                        (<img
                          style={{ width: secondImageHeightQuery, height: secondImageWidthQuery }}
                          className="second_logo"
                          src={secondLogo}
                          srcSet={secondLogo}
                          alt="second_logo"
                        />
                        )
                        :
                        (
                          <img
                            style={{ width: secondImageWidth, height: secondImageHeight }}
                            className="second_logo"
                            src={secondLogo}
                            srcSet={secondLogo}
                            alt="second_logo"
                          />
                        )
                    }
                  </Media>
                </figure>}
            </ul>
          </header>
        </section>
        <section className="nav_cnt" style={{ background: subHeader.background, minHeight: subHeader.height }}>
          <nav className="nav" ref={this.navRef} style={{ justifyContent: subHeader.justifyContent, }}>
            {subHeader.subNavList.length > 0 ? subHeader.subNavList.map((item, i) => {
              return (
                <section key={i} className="nav-item" style={{ display: "block" }}>
                  <a className="title"
                    style={{
                      height: subHeader.tittleHeight,
                      fontSize: subHeader.fontSize,
                      fontWeight: subHeader.fontWeight,
                      padding: subHeader.padding,
                      textAlign: subHeader.textAling
                    }}
                    href={item.link}
                    onClick={item.dropDownItems.length > 0 ? this.navItemClickHandler : null}
                  >
                    {item.name}
                    {item.dropDownItems.length > 0 ? <span className="icon icon-chevron-thin-down" /> : null}
                    {/*<span className="icon icon-chevron-thin-down" />*/}
                  </a>
                  <ul className="dropdown">
                    {item.dropDownItems.length > 0 ? item.dropDownItems.map((dpItem, k) => {
                      return (
                        <li key={k} style={{ background: subHeader.background, borderTop: subHeader.backgroundBorderTop, }} className="dropdown-item">
                          <a
                            className="link"
                            href={dpItem.link}
                            style={{ borderTop: subHeader.backgroundBorderTop, }}
                          >
                            {dpItem.name}
                          </a>
                        </li>
                      )
                    }) : null}
                  </ul>
                </section>
              )
            }) : null}
          </nav>
        </section>
        <section className="warning_cnt">
          <section className="warning">
            <span className="strong">
              {i18next.t('ingreso.mensajeMarchaBlanca.textStrong')}
            </span>
            {i18next.t('ingreso.mensajeMarchaBlanca.text')}
          </section>
        </section>
      </>
    );
  }
}

export default BrandHeader;
