import React, { Component } from 'react';
import './infoErrorPage.scss';
import Button from '../button/button';
import history from '../../config/history';
import PropTypes from 'prop-types';

class InfoErrorPage extends Component {
  componentDidMount() {
    this.props.toggleContactVisible();
  }

  static propTypes = {
    /** Propiedad que indica la imágen a mostrar en la página de error. */
    img: PropTypes.object,
    /** Propiedad que indica la imágen a mostrar en la página de error. */
    imgx2: PropTypes.object,
    /** Propiedad que indica el título de la página error. */
    title: PropTypes.string,
    /** Propiedad que indica el texto a mostrar en la página error. */
    textContent: PropTypes.string,
    /** Propiedad que indica el texto que llevará el botón de la página error. */
    btnText: PropTypes.string,
    /** Propiedad que es una función que hace que el componente contacto desaparezca. */
    toggleContactVisible: PropTypes.func
  };

  render() {
    return (
      <section className="page_container">
        <article className="info_container">
          <figure className="info_container-image">
            <img
              className="img"
              src={this.props.img}
              srcSet={this.props.imgx2}
              alt={'image_info'}
            />
          </figure>
        </article>
        <article className="info_container">
          <span className="info_container-title">{this.props.title}</span>
          <span className="info_container-content">
            {this.props.textContent}
          </span>
          <Button
            id="infoErrorPage_btn_volver_acceso"
            texto={this.props.btnText}
            buttonType="primary_yellow"
            onClick={() => {
              history.replace('/');
              this.props.toggleContactVisible();
            }}
            type="button"
          />
        </article>
      </section>
    );
  }
}

export default InfoErrorPage;
