import StepModel from '../steps/step/stepmodel';
import SubstepModel from '../steps/step/substeps/substep/substepmodel'; 
import CONSTANTS from '../../../../config/constants';

class PartialTracking {
  siniestro = null;
  steps = [];

  constructor(siniestro) {
    this.siniestro = siniestro;
    console.log(this.siniestro);

    this.steps = [
      new StepModel(
        'ingreso',
        'approved',
        this.siniestro.fechaRegistroImplicado,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'documentos',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'taller',
        'pending',
        null,
        this.siniestro.numeroSiniestro,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'analisis',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'entrega',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      )
    ];

    this.steps[3].setSubsteps([
      new SubstepModel(
        'default',
        this.steps[3].step,
        1,
        this.siniestro.categoriaSiniestro
      ),
      new SubstepModel(
        'default',
        this.steps[3].step,
        2,
        this.siniestro.categoriaSiniestro
      ),
      new SubstepModel(
        'default',
        this.steps[3].step,
        3,
        this.siniestro.categoriaSiniestro
      ),
      new SubstepModel(
        'default',
        this.steps[3].step,
        4,
        this.siniestro.categoriaSiniestro
      )
    ]);

    //substeps 4


    this.steps[4].setSubsteps([
      new SubstepModel(
        'default',
        this.steps[4].step,
        1,
        this.siniestro.categoriaSiniestro
      ),
      new SubstepModel(
        'default',
        this.steps[4].step,
        2,
        this.siniestro.categoriaSiniestro
      )
    ]);
    //substeps 4

    this.steps[1].setDate(this.siniestro.fechaCargaDeDocumentacion);

    this.steps[2].setDate(this.siniestro.fechaIngresoATaller);

    this.checkStepWorkshop();
    this.checkFinalStep();
  }

  /**
   * Verifica los estados del paso 4 (Taller)
   */
  checkStepWorkshop = () => {
    console.log('[trackingInfo.js][checkStepWorkshop] Iniciando el método');
    if (
      this.siniestro.fechaPreAnalisis &&
      this.siniestro.fechaPrimerAjuste &&
      this.siniestro.fechaOrdenManoDeObra &&
      this.siniestro.fechaOrdenDeRepuestos
    ) {
      this.steps[3].setDate(this.siniestro.fechaOrdenDeRepuestos);
      this.steps[3].setStatus('approved');
    } else {
      this.steps[3].setStatus('pending');
    }

    this.siniestro.fechaPreAnalisis
      ? this.steps[3].substeps[0].setDate(this.siniestro.fechaPreAnalisis)
      : this.steps[3].substeps[0].setStatus('default');

    this.siniestro.fechaPrimerAjuste
      ? this.steps[3].substeps[1].setDate(this.siniestro.fechaPrimerAjuste)
      : this.steps[3].substeps[1].setStatus('default');

    if (this.siniestro.fechaPreAnalisis && this.siniestro.fechaPrimerAjuste) {
      this.steps[3].substeps[2].setStatus('pending');
    }

    if (this.siniestro.fechaOrdenManoDeObra) {
      this.steps[3].substeps[2].setDate(this.siniestro.fechaOrdenManoDeObra);
      this.steps[3].substeps[3].setStatus('pending');
    }
    if (this.siniestro.tallerImplicado===undefined) {
        this.steps[2].setStatus('default');
      }
    
    this.steps[3].substeps[3].setDate(this.siniestro.fechaOrdenDeRepuestos);
  };

  /**
   * Verifica el estado final del proceso.
   */
  checkFinalStep = () => {
    console.log('[trackingInfo.js][checkFinalStep] Iniciando el método');
    if (
      (this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.CLOSED ||
        this.siniestro.fechaEntregaReal) &&
      this.siniestro.codigoEstadoSiniestro !== CONSTANTS.CLAIM_STATUS.REJECTED
    ) {
      console.log('[trackingInfo.js][checkFinalStep] ESTADO CERRADO');

      if (!this.siniestro.fechaCargaDeDocumentacion) {
        this.steps[1].setStatus('default');
      }

      if (!this.siniestro.fechaIngresoATaller) {
        this.steps[2].setStatus('default');
      }
      
      if (this.siniestro.fechaOrdenDeRepuestos) {
        console.log(
          '[trackingInfo.js][checkFinalStep] Seteando fecha de orden de repuestos'
        );
        this.steps[3].setDate(this.siniestro.fechaOrdenDeRepuestos);
      } else {
        console.log(
          '[trackingInfo.js][checkFinalStep] No trae fecha de orden de repuestos'
        );
      }
      console.log('[trackingInfo.js][checkFinalStep] seteando paso 4');
      if (
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.CLOSED
      ) {
        this.siniestro.fechaEntregaReal
          ? this.steps[4].setDate(this.siniestro.fechaEntregaReal)
          : this.steps[4].setStatus('default');
      } else if (
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.OPEN ||
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.REOPENED
      ) {
        this.siniestro.fechaInicioReparacion
          ? this.steps[4].setDate(this.siniestro.fechaInicioReparacion)
          : this.steps[4].setStatus('pending');
        this.siniestro.fechaEntregaReal
          ? this.steps[4].setDate(this.siniestro.fechaEntregaReal)
          : this.steps[4].setStatus('pending');
      }
      console.log('[trackingInfo.js][checkFinalStep] seteando paso 5');

      let substeps = this.steps[3].substeps;
      substeps = substeps.filter(e => e.status === 'approved');

      if (substeps.length > 0) {
        if (substeps[substeps.length - 1].subStepString === 'adjustment') {
          let substepsSinPrimerAjuste = substeps;
          substepsSinPrimerAjuste = substepsSinPrimerAjuste.filter(
            e => e.subStepString !== 'adjustment'
          );

          if (substepsSinPrimerAjuste.length > 0) {
            this.steps[3].setDate(
              substepsSinPrimerAjuste[substepsSinPrimerAjuste.length - 1].date
            );
          } else {
            this.steps[3].setStatus('default');
          }
        } else {
          this.steps[3].setDate(substeps[substeps.length - 1].date);
        }
      } else if (substeps.length === 0 && this.siniestro.fechaEntregaReal) {
        this.steps[3].setDate(this.siniestro.fechaEntregaReal);
      } else if (substeps.length === 0 && !this.siniestro.fechaEntregaReal) {
        this.steps[3].setStatus('default');
      }
      this.steps[3].substeps = substeps;

    }

    //substeps fechas
      let sunsteps = this.steps[4].substeps;
    if (this.siniestro.fechaOrdenManoDeObra) {
  
      
      if (this.siniestro.fechaEntregaReal!=null) {
        this.steps[4].substeps[1].setStatus('approved');
        this.steps[4].substeps[1].setDate(this.siniestro.fechaEntregaReal);
      } else {
        sunsteps.splice(1,1);
        this.steps[4].substeps = sunsteps;
  
        if (this.siniestro.fechaInicioReparacion!=null) {
          this.steps[4].setStatus('repairing');
        }
      }
      if (this.siniestro.fechaInicioReparacion!=null) {
        this.steps[4].substeps[0].setStatus('repairing');
        this.steps[4].substeps[0].setDate(this.siniestro.fechaInicioReparacion);
      } else {
        sunsteps.splice(0,1);
        this.steps[4].substeps = sunsteps;
      }
      
    } else {
      this.steps[4].setStatus('pendingPayment');
      sunsteps.splice(0,2);
    }
    //sunsteps fechas

    if (
      this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.REJECTED
    ) {
      this.steps[3].setDate(null);
      this.steps[3].setStatus('default');
      this.steps[3].setSubsteps([]);
      this.steps[4].setDate(null);
      this.steps[4].setStatus('rejected');
    }
  };
}

export default PartialTracking;
