import React from 'react';
import './loading.scss';

const loading = props => {
  return (
    <section className="loader_bg">
      <section className="loader_container">
        <div className="loader" />
      </section>
    </section>
  );
};

export default loading;
