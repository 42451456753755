import PropTypes from 'prop-types';
import React from 'react';
import Backdrop from '../backdrop/backdrop';
import Button from '../button/button';
import styles from './modal.module.scss';
import FocusTrap from 'focus-trap-react'

const modal = props => {
  const customStyles = props.isVisible
    ? [styles.modal_container, styles.modal_container_show].join(' ')
    : styles.modal_container;
  let buttonNo = undefined;
  let styleButtonYes = styles.button
  let styleButtonNo = styles.button
  
  if (props.flipButtons) {
    styleButtonYes += ' ' +  styles.buttonMargin; 
  } else {
    styleButtonNo += ' ' +  styles.buttonMargin; 
  }

  if (props.buttonNo) {
    buttonNo = (
      <Button 
          type={props.buttonNo.type}
          href={props.buttonNo.href}
          id={props.buttonNo.buttonId}
          onClick={props.onClickButtonNo}
          styles={styleButtonNo}
          texto={props.buttonNo.text}
          noTarget={props.buttonNo.noTarget}
      >
      </Button>
    )
  }
  let buttonContainer = undefined;
  if (props.flipButtons) { // first "yes" and next "no"
    buttonContainer = (
          <div className = {styles.modal_container_buttons}>
            <Button
              type={props.buttonProps.type}
              href={props.buttonProps.href}
              id={props.buttonProps.buttonId}
              onClick={props.submitModal}
              styles={styleButtonYes}
              texto={props.buttonProps.submitButtonText}
              noTarget={props.buttonProps.noTarget}
            >
              {props.buttonProps.submitButtonIcon ? (
                <span
                  className={
                    styles.button_icon + ' ' + props.buttonProps.submitButtonIcon
                  }
                />
              ) : null}
            </Button>
            {buttonNo}
          </div>
    )
  } else {
    buttonContainer = (
          <div className = {styles.modal_container_buttons}>
            {buttonNo}
            <Button
              type={props.buttonProps.type}
              href={props.buttonProps.href}
              id={props.buttonProps.buttonId}
              onClick={props.submitModal}
              styles={styles.button}
              texto={props.buttonProps.submitButtonText}
              noTarget={props.buttonProps.noTarget}
            >
              {props.buttonProps.submitButtonIcon ? (
                <span
                  className={
                    styles.button_icon + ' ' + props.buttonProps.submitButtonIcon
                  }
                />
              ) : null}
            </Button>
          </div>
    )
  }
  let body = (
      <div>
        <Backdrop onClick={props.closeModal} isVisible={props.isVisible} />
        <section className={customStyles}>
          <article
            id={props.closeModalId}
            onClick={props.closeModal}
            className={styles.button_modal_close}
            style={{
              visibility: props.btnModalCloseIsVisible ? 'visible' : 'hidden'
            }}
          >
            <span className={styles.button_modal_close_icon + ' icon-close'} />
          </article>
          {props.children}
          {buttonContainer}
        </section>
      </div>
  )

  if (props.blockTab) {
    return (
      <FocusTrap>
        {body}
      </FocusTrap>
    );
  } else {
    return body;
  }
};

modal.propTypes = {
  /** Propiedad que indica si el modal se muestra o no. */
  isVisible: PropTypes.bool,
  /** Propiedad de función que se acciona al cerrar el modal. */
  closeModal: PropTypes.func,
  /** Propiedad que indica el id del boton cerrar modal. */
  closeModalId: PropTypes.string,
  /** Propiedad que indica si el boton de cerrar modal es visible. */
  btnModalCloseIsVisible: PropTypes.bool,
  /** Propiedad que tiene el contenido que estará dentro del modal. */
  children: PropTypes.object,
  /** Propiedad que indica los atributos que tendrá el boton. */
  buttonProps: PropTypes.object,
  /** Propiedad de función que se acciona al accionar el botón principal del modal. */
  submitModal: PropTypes.func,
  /** Si es true, usa Focus Trap para bloquear el cambio de focus al presionar
  * tab para que no salga del modal*/
  blockTab: PropTypes.bool
};

export default modal;
