import React, { Component } from 'react';
import { connect } from 'react-redux';
import history from '../../config/history';
import Button from '../../shared/button/button';
import PropTypes from 'prop-types';
import styles from './spareParts.module.scss';
import Table from './table/table';
import i18next from '../../config/i18next';
import Modal from '../../shared/modal/modal';
import { FaExclamationCircle } from 'react-icons/fa';
import Loading from '../../shared/loading/loading';
import * as actionsApp from '../../App.actions.js';
import * as actions from './spareParts.actions';
import { QR_IDENTIFIER } from '../../config/constants';

const verifyAliro = () => {
  let urlName = window.location.hostname;
  if (urlName === process.env.REACT_APP_URL_ALIRO) {
    return true;
  }
  return false;
}

class SpareParts extends Component {

  gtag = window.gtag;


  constructor(props) {
    super(props);
    this.state = {
      disableVehicleInWorkshop: false,
      disableReception: true,
      vehicleInWorkshop: undefined,
      nameReceptionist: "",
      identifierReceptionist: "",
      disableButtonSave: false
    }

    let request = this.props.request
    console.log("request: ", request)

    if ( request === undefined || 
         (Object.entries(request).length === 0 && request.constructor === Object) ||
         verifyAliro() ) {
			history.replace('/' + QR_IDENTIFIER);
		} else {
      this.numeroSiniestro = request.numeroSiniestro;
      this.guia = request.numeroGuia;
      let items = request.spareParts;
      
      if (this.allCheckInOk(items) || request.guideStatus === "S") {
        this.state = {
          disableReception: true,
          nameReceptionist: request.receptionistName,
          identifierReceptionist: request.receptionistIdentifier,
          vehicleInWorkshop: request.vehicleInWorkshop === 1,
          disableButtonSave: true
        }
      } else if (request.vehicleInWorkshop === 1) { 
        this.state = {
          disableReception: false,
          disableVehicleInWorkshop: false,
          vehicleInWorkshop: true
        }
      }

      this.props.whatsappVisibility(false);
      this.props.setSpareParts(request.spareParts)
    }
  }

  static propsTypes = {
    request: PropTypes.object 
  }


  save () {
    let data = {
      noticeNumber: this.numeroSiniestro,
      guideNumber: this.guia,
      nameReceptionist: this.state.nameReceptionist,
      identifierReceptionist: this.state.identifierReceptionist,
      vehicleInWorkshop: this.state.vehicleInWorkshop
    }
    let items = this.props.items.filter( (item) => {
      return item.changed && item.changed === true && item.receptionStatus !== -1
    })
    this.props.saveSpareParts(data, items)
  }

  saveSpareParts = () => {
    let canSave = this.validateDataToSave()
    if (canSave === false) {
      return;
    }
    this.setState({
      errorMessageSave: undefined,
    })
    let hasWarnings = this.hasWarnings()

    if (canSave && !hasWarnings) {
      this.setState({
        errorMessageSave: undefined,
        modal: undefined,
      })
      this.save();
    }
  }

  buttonBack = () => {
    let atLeastOneModify = this.checkAtLeastOneModify();
    if (atLeastOneModify) {
      this.setState({
        modal: this.createBackModal(i18next.t('spareParts.messages.backConfirm'))
      })
    }else {
      this.setState({
        modal: this.createBackModal(i18next.t('spareParts.messages.backWithoutChanges'))
      })
    }
  }

  changeOnItem = (item) => {
    console.log("changeOnitem: ", item)
    this.setState({ disableReception: false })
  }

  goBack() {
    history.goBack();
  }

  validateDataToSave() {
    let items = this.props.items;

    let dataReceptionIsOk = this.validateReceptionData()

    if (dataReceptionIsOk === false) {
      this.setState({
        errorMessageSave: i18next.t('spareParts.messages.saveWithoutReceptionData')
      })
    }

    // Spare Parts
    let atLeastOneModify = this.checkAtLeastOneModify();

    let allItemChecked = items.every( item => {
      return item.receptionStatus !== -1 ;
    })

    
    if (atLeastOneModify === false && allItemChecked === false) {
      this.setState({
        errorMessageSave: i18next.t('spareParts.messages.saveNotChecked')
      })
    } else if (atLeastOneModify === false && allItemChecked === true) {
      this.setState({
        errorMessageSave: i18next.t('spareParts.messages.saveAllChecked')
      })
    } 

    return atLeastOneModify === true && dataReceptionIsOk;
  }

  allCheckInOk(items) {
    return items.every( item => {
      return item.receptionStatus === 1;
    })
  }

  allCheckNoOK(items) {
    return items.every( item => {
      return item.receptionStatus === 0;
    })
  }


  checkAtLeastOneModify() {
    let items = this.props.items;
    return items.some( item => {
      let changed = item.changed;
      return changed !== undefined && changed === true
    });
  }

  hasWarnings () {
    let items = this.props.items;
    let someItemWithoutChange = items.some( item => {
      return item.changed === undefined && item.receptionStatus === -1;
    })

    if (someItemWithoutChange) {
      let modal = this.createSaveModal(i18next.t('spareParts.messages.saveCheckedNotCompleted'));
      this.setState({modal: modal }) 
    }

    return someItemWithoutChange;
  }

  validateReceptionData() {
    let name = this.state.nameReceptionist
    let identifier = this.state.identifierReceptionist
    let vehicleInWorkshop = this.state.vehicleInWorkshop
    if (name === undefined || name === '') {
      return false
    }
    if (identifier === undefined || identifier === '') {
      return false
    }
    let identifierIsValid = this.valida_rut(identifier);
    if (identifierIsValid === false) {
      return false;
    }
    if (vehicleInWorkshop === undefined || vehicleInWorkshop === '') {
      return false
    }
    return true
  }

  createSaveModal(msg) {
    return this.createModal(
      msg,
      this.closeModal,
      this.modalSubmitSave,
      i18next.t('spareParts.modalReason.buttonOk')
    );
  }

  createBackModal(msg) {
    return this.createModal(
      msg,
      this.closeModal,
      this.modalSubmitBack,
      i18next.t('spareParts.modalReason.buttonOk')
    );
  }

  createModal (msg, fnClose, fnSubmit, btnText) {
    let children = (
      <div className = {styles.modal_container}>
        <div className = {styles.modal_icon}>
          <FaExclamationCircle size={30}/>
        </div>
        <div className = {styles.modal_item}>
          <p className = {styles.modal_item_selected} >
            {msg}
          </p>
        </div>
      </div>
    )
    return (
      <Modal
        isVisible={true}
        submitModal={fnSubmit}
        closeModal={fnClose}
        onClickButtonNo={fnClose}
        btnModalCloseIsVisible={false}
        flipButtons = {true}
        blockTab = {true}
        buttonProps={{
          buttonId: 'button_si',
          submitButtonText: btnText,
          type: 'button'
        }}
        buttonNo = {{
          buttonId: 'button_no',
          text: i18next.t('spareParts.modalReason.buttonNo'),
          type: 'button'
        }}
      >
          {children}
      </Modal>
    )
  }

  closeModal = () => {
    this.setState({modal: undefined})
  }

  modalSubmitSave = () => {
    this.setState({modal: undefined})
    this.save()
  }

  modalSubmitBack = () => {
    this.setState({modal: undefined})
    this.goBack()
  }


  changeNameReceptionist = (event) => {
    this.setState({ nameReceptionist: event.target.value })
  }
  changeIdentifierReceptionist = (event) => {
    let identifier = event.target.value;
    let rutIsBad = !this.valida_rut(identifier)

    this.setState({ 
      identifierReceptionist: identifier,
      identifierReceptionistIsBad: rutIsBad
    })
  }
  changeYes = (event) => {
    this.setState({ vehicleInWorkshop: true })
  }
  changeNo = (event) => {
    this.setState({ vehicleInWorkshop: false })
  }

  render() {
    if (this.state === null) {
      return <></>;
    }
    return (
      <div>
        {this.props.isFetching ? <Loading /> : null}
      { this.props.resultSave === undefined && 
        <section className={styles.spare_parts_container}>
          <h2 className={styles.spare_parts_title}>
            Recepción de Repuestos
          </h2>
          <section className={styles.spare_parts_data}>
            <ul className={styles.spare_parts_list}>
              {this.createLiElement(
                i18next.t('spareParts.noticeData.noticeNumber'),
                this.numeroSiniestro
              )}
              {this.createLiElement(
                i18next.t('spareParts.noticeData.guideNumber'),
                this.guia
              )}
              {this.createLiElement(
                i18next.t('spareParts.noticeData.workshop'),
                this.props.request.workshopName
              )}
              {this.createLiElement(
                i18next.t('spareParts.noticeData.provider'),
                this.props.request.provider
              )}
            </ul>
          </section>

          <Table
            markNotReceived = {false}
            changeOnItem = {this.changeOnItem}
          />

          <div className = {styles.reception_data}>
            <h3>Datos recepción</h3>
            <div className = {styles.reception_data_item}>
              <span>
                {i18next.t('spareParts.receptionData.nameReceptionist')}
              </span>
              <span className = {styles.reception_data_input_required}>*</span>
              <input type="text"
                onChange={this.changeNameReceptionist}
                maxLength="50"
                value = {this.state.nameReceptionist}
                disabled = {this.state.disableReception}
              />
            </div>

            <div className = {styles.reception_data_item}>
              <span>
                {i18next.t('spareParts.receptionData.identifierReceptionist')}
              </span>
              <span className = {styles.reception_data_input_required}>*</span>
              <input 
                type="text"
                onChange={this.changeIdentifierReceptionist}
                value = {this.state.identifierReceptionist}
                maxLength="10"
                disabled = {this.state.disableReception}
              />
              { this.state.identifierReceptionistIsBad  &&
                <span className = {styles.spare_parts_text_error_2}>
                  {i18next.t('spareParts.receptionData.invalidIdentifier')}
                </span>
              }
            </div>

            <div className = {styles.reception_data_vehicle}>
              <span>
                {i18next.t('spareParts.receptionData.vehicleInWorkshop')}
              </span>
              <span className = {styles.reception_data_input_required}> *</span>
              <span className = {styles.sep}>
                Sí 
              </span>
              <input 
                type="radio"
                onChange={this.changeYes}
                checked = {this.state.vehicleInWorkshop === true}
                disabled = {this.state.disableReception || this.state.disableVehicleInWorkshop}
              />
              <span className = {styles.sep}>
                No
              </span>
              <input 
                type="radio" 
                onChange = {this.changeNo}
                checked = {this.state.vehicleInWorkshop === false}
                disabled = {this.state.disableReception || this.state.disableVehicleInWorkshop}
              />
            </div>
          </div>
          <div className = {styles.spare_parts_buttons_container}>
            <Button
              id={"button_back"}
              texto={"Volver"}
              styles={styles.spare_parts_back}
              buttonType="primary_yellow"
              onClick={this.buttonBack}
            />
            <Button
              id={"button_save"}
              texto={"Guardar"}
              styles={styles.spare_parts_save}
              buttonType="primary_yellow"
              onClick={this.saveSpareParts}
              disabled = {this.state.disableButtonSave}
            />
          </div>
          { this.state.errorMessageSave && 
            <div className={styles.spare_parts_text_error}>
                <p> {this.state.errorMessageSave} </p>
            </div>
          }
          {this.state.modal}
        </section>
      }
      { this.props.resultSave && this.props.resultSave.saveSuccefull &&
          <section className={styles.spare_parts_container}>
            <h2 className = {styles.spare_parts_message_save}>
              {i18next.t('spareParts.messages.saveOk')}
            </h2>
            <Button
              id={"button_back_ok"}
              texto={"Volver"}
              styles={styles.spare_parts_save}
              buttonType="primary_yellow"
              onClick={this.backAfterSave}
            />
          </section>
      }
      { this.props.resultSave && this.props.resultSave.saveSuccefull === false &&
          <section className={styles.spare_parts_container}>
            <h2 className = {styles.spare_parts_message_save}>
              {i18next.t('spareParts.messages.saveError')}
            </h2>
            <Button
              id={"button_back_error"}
              texto={"Volver"}
              styles={styles.spare_parts_save}
              buttonType="primary_yellow"
              onClick={this.backAfterError}
            />
          </section>
      }
      </div>
    )
  }

  backAfterSave = () => {
    history.replace('/#repuestos');
    this.props.cleanResponsePost();
  }
  backAfterError = () => {
    this.props.cleanResponsePost();
  }

  createLiElement(text, value) {
    return (
      <li className={styles.spare_parts_list_item}>
        <div className={styles.spare_parts_list_text_t}>
          {text}:
        </div>
        <div className={styles.spare_parts_list_text}>
          {value}
        </div>
      </li>
    )
  } 

  valida_rut(rut) {
    if (rut === null) {
        rut = "";
    }
    rut = rut.toString();
    rut = rut.split('.').join('');
    rut = rut.split('-').join('');

    if (rut.length <= 2) {
      return false;
    }

    //Valor acumulado para el calculo de la formula
    var nAcumula = 0;
    //Factor por el cual se debe multiplicar el valor de la posicion
    var nFactor = 2;
    //Digito verificador
    var nDv = 0;
    var nDvReal;
    //extraemos el ultimo número o letra que corresponde al verificador
    //La K corresponde a 10
    if (rut.charAt(rut.length - 1).toUpperCase() === 'K')
        nDvReal = 10;
    //el 0 corresponde a 11
    else if (rut.charAt(rut.length - 1) === '0')
        nDvReal = 11;
    else
        nDvReal = rut.charAt(rut.length - 1);
    for (var nPos = rut.length - 2; nPos >= 0; nPos--) {
        nAcumula += rut.charAt(nPos).valueOf() * nFactor;
        nFactor++;
        if (nFactor > 7) nFactor = 2;
    }
    if(nAcumula === 0){
        return false;
    }
    nDv = 11 - (nAcumula % 11)
    return (nDv === parseInt(nDvReal));
  }
}

const mapStateToProps = state => {
  return {
    request: state.spareParts.request,
    items: state.spareParts.items,
    isFetching: state.spareParts.isFetching,
    resultSave: state.spareParts.result
  };
};

const mapDispatchToProps = dispatch => ({
  whatsappVisibility: isVisible =>
    dispatch(actionsApp.showWhatsappIcons(isVisible)),
  setSpareParts: changedItems =>
    dispatch(actions.setSpareParts(changedItems)),
  saveSpareParts: (data, items) =>
    dispatch(actions.saveSpareParts(data, items)),
  cleanResponsePost: changedItems =>
    dispatch(actions.cleanResponsePost(changedItems)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpareParts);
