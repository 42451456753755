import React from 'react';
import SubStep from './substep/substep';
import styles from './substeps.module.scss';
import PropTypes from 'prop-types';

const subSteps = props => {
  const substepsMap = props.substeps.map((substep, index) => {
    return (
      <SubStep
        date={substep.date}
        label={substep.label}
        status={substep.status}
        key={index}
      />
    );
  });
  return <section className={styles.container}>{substepsMap}</section>;
};

subSteps.propTypes = {
  /** Propiedad que indica las subetapas de la etapa. */
  substeps: PropTypes.array
};

export default subSteps;
