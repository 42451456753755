import React, { Component } from 'react';
import Step from './step/step';
import styles from './steps.module.scss';
import PropTypes from 'prop-types';

class Steps extends Component {
  constructor(props) {
    super(props);
    this.props = props;
    this.stepsRef = React.createRef();
    this.stepsMap = this.props.steps.map((step, index) => {
      console.log(step.step);
      return (
        <Step
          step={step.step}
          date={step.date}
          label={step.label}
          helpText={step.helpText}
          buttonText={step.buttonText}
          buttonLink={step.buttonLink}
          image={step.image}
          altImage={step.altImage}
          status={step.status}
          key={index}
          substeps={step.substeps}
          claimStatus={this.props.claimStatus}
          claimNumber={this.props.claimNumber}
          eventCategory={step.eventCategory}
          eventLabel={step.eventLabel}
          claimCategory={this.props.claimCategory}
          id={'steps_' + index}
        />
      );
    });
  }

  render() {
    return (
      <section className={styles.container} ref={this.stepsRef}>
        {this.stepsMap}
      </section>
    );
  }
}

Steps.propTypes = {
  /** Propiedad que tiene todas las estapas del siniestro. */
  steps: PropTypes.array,
  /** Propiedad que indica el estado del siniestro. */
  claimStatus: PropTypes.string,
  /** Propiedad que indica el número del siniestro. */
  claimNumber: PropTypes.string,
  /** Propiedad que indica la categoría del Siniestro. */
  claimCategory: PropTypes.number
};

export default Steps;
