import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from '../../config/i18next';
import * as actions from './workshopAdmission.actions';
import styles from './workshopAdmission.module.scss';
import Form from './form/form';
import Modal from '../../shared/modal/modal';
import Loading from '../../shared/loading/loading';
import { checkIfMobile } from '../../shared/functions/utils';
import PropTypes from 'prop-types';
import Message from './message/message';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

class WorkshopAdmission extends Component {
	isMobile = false;
	gtag = window.gtag;

	constructor(props) {
		super(props);
		this.inputRef = React.createRef();
		this.isMobile = checkIfMobile();

		this.formSubmitHandler = this.formSubmitHandler.bind(this);
		this.messageSubmitHandler = this.messageSubmitHandler.bind(this);
	}

	static propTypes = {
		/** Propiedad de función que se acciona para consultar el siniestro. */
		saveWorkshopAdmission: PropTypes.func,
		/** Propiedad de función que se acciona para indicar que hubo un error en la app. */
		comunicarError: PropTypes.func,
		/** Propiedad que indica el error ocurrido en la app. */
		error: PropTypes.object,
		/** Propiedad que indica si el servicio rest aún está trabajando */
		isFetching: PropTypes.bool
	};

	state = {
		modalIsVisible: false,
		numSiniestro: '',
		showFormError: false,
		formIsValid: false,
		newDate: null,
		errorMessage: i18next.t('workshopAdmission.form.errorMessage'),
		initialDate: null
	};

	whatsappLink = this.isMobile
		? i18next.t('shared.contact.whatsappLinkMobile')
		: i18next.t('shared.contact.whatsappLinkDesktop');

	image = require('../../../assets/img/banner-img.jpg');
	image2x = require('../../../assets/img/banner-img@2x.jpg');

	saveWorkshopAdmissionAction = (noticeNumber, admissionDate) => {
		console.log('saveWorkshopAdmissionAction');
		this.props.saveWorkshopAdmission(noticeNumber, admissionDate);
	};

	modalClickHandler = () => {
		console.log('Presionando botón Aceptar Modal');
		this.props.comunicarError({ codigo: '', mensaje: '' });
		this.setState({
			numSiniestro: ''
		});
	};

	inputBlurHandler = () => {
		console.log('Ejecutando inputBlurHandler');
		if (this.state.numSiniestro === '') {
			this.setState(previusState => {
				return {
					...previusState,
					showFormError: true,
					errorMessage: i18next.t('workshopAdmission.form.errorMessageEmptyInput')
				};
			});
		} else if (
			this.state.numSiniestro.length < 7 &&
			this.state.numSiniestro.length > 0
		) {
			this.setState(previusState => {
				return {
					...previusState,
					showFormError: true,
					errorMessage: i18next.t('workshopAdmission.form.errorMessage')
				};
			});
		} else if (this.state.numSiniestro && this.state.numSiniestro.length >= 7) {
			this.setState(previusState => {
				return {
					...previusState,
					showFormError: false
				};
			});
		}
	};

	inputFocusHandler = () => {
		this.setState(previusState => {
			return {
				showFormError: false
			};
		});
	};

	inputChangeHandler = event => {
		const value = event.target.value.replace(/\D/g, '');
		this.setState(previusState => {
			return {
				numSiniestro: value
			};
		});
		if (value && value.length >= 7) {
			this.setState(previusState => {
				return {
					formIsValid: true
				};
			});
		} else if (value && value.length < 7) {
			this.setState(previusState => {
				return {
					formIsValid: false
				};
			});
		}
	};

	formSubmitHandler(event, noticeNumber, admissionDate) { // Hacer: change this
		console.log(admissionDate);
		event.preventDefault();
		ReactGA.event({
			category: 'workshop_admission',
			action: 'click',
			label: 'workshop_admission',
		});
		console.log('formSubmitHandler');
		console.log('numSiniestro: [', noticeNumber, ']');
		this.saveWorkshopAdmissionAction(noticeNumber, admissionDate);
		let isoDate = admissionDate.toISOString().split('T')[0];
		let parts = isoDate.split('-');
		let formatedDate = parts[2] + '/' + parts[1] + '/' + parts[0];

		this.setState({newDate: formatedDate});
	}

	messageSubmitHandler() {
		console.log("messageSubmitHandler");
		ReactGA.event({
			category: 'workshop_admission',
			action: 'click',
			label: 'workshop_admission',
		});

		this.setState({
			redirectToTracking: true
		})
		console.log("messageSubmitHandler finished");
	}

	getFechaInicial () {
		let tmpDate = localStorage.getItem('init');
		let parts = tmpDate.split('/');
		return parts[2] + '/' + parts[1] + '/' + parts[0];
	}

	render() {
		if (this.props.location && this.props.location.search) {
			var trackingURL = '/siniestro/' + this.props.match.params.numSiniestro;
			return (
				<>
					{this.state.redirectToTracking ? <Redirect to={{ pathname: trackingURL, state: {nuevaFecha: this.state.newDate} }} /> : null}
					{this.props.isFetching ? <Loading /> : null}
					{this.modal}
					<section className={styles.workshopAdmission_container}>
						<section className={styles.main_container}>
							<Message
								workshopAdmissionButtonText={i18next.t('workshopAdmission.successform.insuredButtonText')}
								title={i18next.t('workshopAdmission.successform.title')}
								subtitle={i18next.t('workshopAdmission.successform.subtitle')}
								info={i18next.t('workshopAdmission.successform.info')}
								footnote={i18next.t('workshopAdmission.successform.footnote')}
								numSiniestro={this.props.match.params.numSiniestro}
								errorMessage={this.state.errorMessage}
								onWorkshopAdmissionSubmit={this.messageSubmitHandler}
								isValid={this.state.formIsValid}
								showError={this.state.showFormError}
								workshopAdmissionButtonId="workshopAdmission_form_asegurado_button"
								ref={this.inputRef}
								tooltipMessage={i18next.t('workshopAdmission.successform.tooltipMessage')}
								infoId="workshopAdmission_form_input_info"
							/>
						</section>
					</section>
				</>
			);
		}

		if (this.props.error.codigo !== '') {
			this.modal = (
				<Modal
					closeModalId="workshopAdmission_modal_close_button"
					isVisible={true}
					submitModal={this.modalClickHandler}
					closeModal={this.modalClickHandler}
					btnModalCloseIsVisible={true}
					buttonProps={{
						buttonId: 'workshopAdmission_modal_button',
						submitButtonIcon: 'icon-whatsapp',
						submitButtonText: i18next.t('workshopAdmission.modal.submitButton'),
						type: 'link',
						href: this.whatsappLink
					}}
				>
					<section className={styles.modal_content}>
						<span className={styles.modal_content_icon + ' icon-advertencia'} />
						<p className={styles.modal_content_text}>
							{i18next.t('workshopAdmission.modal.text')}{' '}
							<strong className="strong">
								{i18next.t('workshopAdmission.modal.textStrong')}
							</strong>
						</p>
					</section>
				</Modal>
			);
		} else {
			this.modal = null;
		}

		return (
			<>
				{this.props.isFetching ? <Loading /> : null}
				{this.modal}
				<section className={styles.workshopAdmission_container}>
					<section className={styles.main_container}>
						<Form
							placeholder={i18next.t('workshopAdmission.form.placeholder')}
							workshopAdmissionButtonText={i18next.t('workshopAdmission.form.insuredButtonText')}
							title={i18next.t('workshopAdmission.form.title')}
							subtitle={i18next.t('workshopAdmission.form.subtitle')}
							info={i18next.t('workshopAdmission.form.info')}
							datePickerPlaceholderText={i18next.t('workshopAdmission.form.datepicker.placeholderText')}
							footnote={i18next.t('workshopAdmission.form.footnote')}
							numSiniestro={this.props.match.params.numSiniestro}
							errorMessage={i18next.t('workshopAdmission.form.errorMessage')}
							errorMessageEmptyInput={i18next.t('workshopAdmission.form.errorMessageEmptyInput')}
							onWorkshopAdmissionSubmit={this.formSubmitHandler}
							isValid={this.state.formIsValid}
							showError={this.state.showFormError}
							workshopAdmissionButtonId="workshopAdmission_form_asegurado_button"
							ref={this.inputRef}
							tooltipMessage={i18next.t('workshopAdmission.form.tooltipMessage')}
							infoId="workshopAdmission_form_input_info"
							initialDate={this.getFechaInicial()}
						/>
					</section>
				</section>
			</>
		);
	}
}

const mapStateToProps = state => {
	return {
		siniestro: state.siniestro.siniestro,
		error: state.siniestro.error,
		isFetching: state.siniestro.isFetching
	};
};

const mapDispatchToProps = dispatch => ({
	saveWorkshopAdmission: (noticeNumber, admissionDate) =>
		dispatch(actions.saveWorkshopAdmission(noticeNumber, admissionDate)),
	comunicarError: error => dispatch(actions.comunicarError(error))
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(WorkshopAdmission);
