import React, { Component } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import Waypoint from "react-waypoint";
import "babel-polyfill";
import "url-polyfill";
import "url-search-params-polyfill";
import "./App.scss";
import Ingreso from "./modules/ingreso/ingreso";
import Header from "./shared/header/header";
import BrandHeader from "./shared/brandHeader/brandHeader";
import Footer from "./shared/footer/footer";
import BrandFooter from "./shared/brandFooter/brandFooter";
import Contact from "./shared/contact/contact";
import BrandContact from "./shared/brandContact/brandContact";
import Tracking from "./modules/tracking/tracking";
import SpareParts from "./modules/spareParts/spareParts";
import Callback from "./modules/callback/callback";
import history from "./config/history";
import { checkIfMobile } from "./shared/functions/utils";
import ScrollToTop from "./shared/scrollToTop/scrollToTop";
import InfoErrorPage from "./shared/infoErrorPage/infoErrorPage";
import i18next from "./config/i18next";
import BRAND_2 from "./brands/Brands";
import {
  LIBERTY_ANALITYCS_KEY,
  ALIRO_ANALITYCS_KEY
} from "../app/config/constants";
import ReactGA from "react-ga";
import Favicon from "react-favicon";
import WorkshopAdmission from "./modules/workshopAdmission/workshopAdmission";
const faviconLiberty = require("../assets/icons/favicon.ico");
const faviconAliro = require("../assets/icons/faviconAliro.ico");
require("disable-react-devtools");

class App extends Component {
  isMobile = false;

  constructor(props) {
    super(props);
    console.log(
      "Seguimiento de Siniestro versión:",
      process.env.REACT_APP_VERSION
    );
    console.log("Env", process.env.NODE_ENV);
    console.log(
      "Tiempo de compilación:",
      process.env.REACT_APP_COMPILATION_DATETIME
    );
    this.isMobile = checkIfMobile();
    this.appComponent = React.createRef();
    this.imgError404 = require("../assets/img/no-found.png");
    this.imgError404x2 = require("../assets/img/no-found_x2.png");
    this.imgError500 = require("../assets/img/face.png");
    this.imgError500x2 = require("../assets/img/face_x2.png");
    if (
      window.location.hostname.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0
    ) {
      ReactGA.initialize(ALIRO_ANALITYCS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    } else {
      ReactGA.initialize(LIBERTY_ANALITYCS_KEY);
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }

  state = {
    footerIsVisible: false,
    contactVisible: true
  };

  toggleContactVisible = () => {
    this.setState({
      contactVisible: !this.state.contactVisible
    });
  };

  handleWaypointEnter = () => {
    this.setState({
      footerIsVisible: true
    });
  };

  handleWaypointLeave = () => {
    this.setState({
      footerIsVisible: false
    });
  };

  verifyAliro() {
    let urlName = window.location.hostname;
    if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {
      return true;
    }
    return false;
  }

  render() {
    return (
      <Router history={history}>
        <ScrollToTop>
          <div style={{ width: "100%" }}>
            <section
              className={
                this.state.contactVisible ? "app" : "app app_padding_none"
              }
              ref={this.appComponent}
            >
              {this.verifyAliro() ? (
                <Favicon url={faviconAliro} />
              ) : (
                <Favicon url={faviconLiberty} />
              )}
              {this.verifyAliro() ? (
                <BrandHeader brand={BRAND_2} />
              ) : (
                <Header />
              )}
              <Switch>
                <Route path="/" exact component={Ingreso} />
                <Route path="/siniestro/:numSiniestro" component={Tracking} />
                <Route
                  path="/repuestos/:numSiniestro/:numGuia"
                  component={SpareParts}
                />
                <Route
                  path="/ingreso_taller/:numSiniestro"
                  component={WorkshopAdmission}
                />
                <Route
                  path="/error"
                  render={() => (
                    <InfoErrorPage
                      toggleContactVisible={this.toggleContactVisible}
                      img={this.imgError500}
                      imgx2={this.imgError500x2}
                      title={i18next.t("infoErrorPage.error500.title")}
                      textContent={i18next.t(
                        "infoErrorPage.error500.textContent"
                      )}
                      btnText={i18next.t("infoErrorPage.error500.btnText")}
                    />
                  )}
                />
                <Route
                  path="/pagoenlinea"
                  render={() => (
                    <div className="container container-main">
                      {this.verifyAliro() ? (
                        <iframe
                          title="cuotasAliro"
                          height="350px"
                          src="https://www.liberty.cl/PagoCuotasWEB/ObtenerCuotasAliro"
                          style={{
                            border: 0,
                            position: "relative",
                            minHeight: "91vh",
                            paddingTop: "5vh"
                          }}
                          width="100%"
                          scrolling="yes"
                        ></iframe>
                      ) : (
                        <iframe
                          title="cuotas"
                          src="https://www.liberty.cl/PagoCuotasWEB/ObtenerCuotas"
                          width="100%"
                          align="center"
                        ></iframe>
                      )}
                    </div>
                  )}
                />
                <Route path="/callback" component={Callback} />
                <Route
                  render={() => (
                    <InfoErrorPage
                      toggleContactVisible={this.toggleContactVisible}
                      img={this.imgError404}
                      imgx2={this.imgError404x2}
                      title={i18next.t("infoErrorPage.error404.title")}
                      textContent={i18next.t(
                        "infoErrorPage.error404.textContent"
                      )}
                      btnText={i18next.t("infoErrorPage.error404.btnText")}
                    />
                  )}
                />
              </Switch>
              {this.state.contactVisible && this.props.isWhatsappVisible && (
                <div>
                  {this.verifyAliro() ? (
                    <BrandContact
                      brand={BRAND_2}
                      isMobile={this.isMobile}
                      footerIsVisible={this.state.footerIsVisible}
                    />
                  ) : (
                    <Contact
                      isMobile={this.isMobile}
                      footerIsVisible={this.state.footerIsVisible}
                    />
                  )}
                </div>
              )}
            </section>
            <Waypoint
              onEnter={this.handleWaypointEnter}
              onLeave={this.handleWaypointLeave}
            />
            {this.verifyAliro() ? <BrandFooter brand={BRAND_2} /> : <Footer />}
          </div>
        </ScrollToTop>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  return {
    isWhatsappVisible: state.global.whatsappVisibility
  };
};

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(App);
