export const checkIfMobile = () => {
  return (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i)
  );
};

export const stringToDate = dateStr => {
  const dateObj = this.splitDate(dateStr);
  return new Date(dateObj.year, dateObj.month, dateObj.day);
}

export const splitDate = (dateStr) => {
  const dateObj = { day: null, month: null, year: null };
  dateObj.day = +dateStr.split('-')[0];
  dateObj.month = +dateStr.split('-')[1] - 1;
  dateObj.year = +dateStr.split('-')[2];
  return dateObj;
}
