/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';

class Brandfooter extends Component {
  constructor(props) {
    super(props);
    this.logo = require(`../../../assets/img/${props.brand.style.footer.imageSrc}`);
    this.logo2x = require(`../../../assets/img/${props.brand.style.footer.imageSrc}`);
    this.logoDefensoria = require(`../../../assets/img/${props.brand.style.footer.imageDefensoriaSrc}`);
    this.logoDefensoria2x = require(`../../../assets/img/${props.brand.style.footer.imageDefensoriaSrc}`);
    this.footerRef = React.createRef();
    if (this.verifyAliro()) {

      import('./brandFooter.scss')
        .then((Baz) => {
          console.log(Baz);
        });
    }
  }

  verifyAliro() {
    let urlName = window.location.hostname;

    if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {
      return true;
    }
    return false;
  }

  render() {
    let {
      href,
      imgAlt,
      padding,
      footerLogoDisplay,
      footerItems,
      //backgroundColor,
      footerListStyle,
      socialAreaVisible,
      imageGrupoLiberty
    } = this.props.brand.style.footer;

    this.logoGrupoLiberty = require(`../../../assets/img/${imageGrupoLiberty}`);

    return (
      <footer className="footer_container" style={{ padding: "1rem 0.5rem 0rem 0.5rem", backgroundColor: "rgb(0, 199, 177)" }} ref={this.footerRef}>
        <section className="footer" style={{ padding: padding, marginTop: 0 }}>
          <section className="footer_logo" style={{ display: footerLogoDisplay }}>
            <figure style={{ padding: "35px 8px 0px 0px" }} className="logo">
              <a href={href}>
                <img
                  className="logo_img"
                  src={this.logo}
                  srcSet={this.logo2x}
                  alt={imgAlt}
                />
              </a>
            </figure>
          </section>
          <section className="footer_links" style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: "8px" }}>


            {footerItems.length > 0 ? footerItems.map((item, i) => {
              return (
                <ul key={i} style={{ width: footerListStyle.width, padding: footerListStyle.padding, marginRight: "10px" }} className="list">
                  <li className="list_item" style={{ margin: "0px -20px 10px -20px", textAlign: "center" }}>
                    <a href={item.link}
                      style={{
                        color: item.fontColor,
                        fontWeight: item.fontWeight,
                        fontFamily: "Roboto",
                        fontSize: item.fontSize,
                        textDecoration: "none",
                        letterSpacing: item.letterSpacing,
                        lineHeight: item.lineHeight,
                      }} className="title">
                      {item.name}
                    </a>
                  </li>
                  {item.downList.length > 0 ? item.downList.map((itemList) => {
                    return (
                      <li key={i} className="list_item" style={{ paddingBottom: "10px", textAlign: "center" }}>
                        <a
                          style={{
                            color: itemList.fontColor,
                            textDecoration: itemList.textDecoration,
                            fontSize: itemList.fontSize,
                            fontWeight: 400
                          }}
                          className="link"
                          href={itemList.href}
                          title={itemList.title}
                        >
                          {itemList.name}
                        </a>
                      </li>
                    )
                  }) : null}

                </ul>
              )
            }) : null}

          </section>

          {socialAreaVisible ?
            <section className="footer_social">
              <p className="title">Síguenos:</p>
              <a
                className="link"
                href="https://www.facebook.com/LibertySegurosCl"
              >
                <span className="icon-facebook icon" aria-hidden="true" />
              </a>
              <a className="link" href="https://twitter.com/LibertySegChile">
                <span className="icon-twitter icon" aria-hidden="true" />
              </a>

              <a className="link" href="#">
                <span className="icon-instagram icon" aria-hidden="true" />
              </a>
              <a
                className="link link--nobackground"
                href="https://www.ddachile.cl/Inicio.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="image"
                  src={this.logoDefensoria}
                  srcSet={this.logoDefensoria2x}
                  alt="Logo Defensoría"
                />
              </a>
            </section>
            :
            <section className="footer_social" style={{ paddingTop: "15px", paddingBottom: "20px" }}>
              <a
                className="link link--nobackground"
                href="https://www.ddachile.cl/Inicio.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="image"
                  src={this.logoDefensoria}
                  srcSet={this.logoDefensoria2x}
                  alt="Logo Defensoría"
                />
              </a>
              <a href="https://www.liberty.cl" title="Grupo Liberty"><img style={{ height: "50px", width: "175px", marginTop: "0px" }} src={this.logoGrupoLiberty}
                alt="Logo Defensor del Asegurado"></img></a>
            </section>
          }
        </section>
      </footer>
    );
  }
}

export default Brandfooter;
