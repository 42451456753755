import React, { Component } from 'react';
import Button from '../../../shared/button/button';
import styles from './form.module.scss';
import PropTypes from 'prop-types';
import moment from 'moment';
import MaterialPicker from "../../../shared/materialPicker/materialPicker";




class Form extends Component {

	constructor(props) {
		super(props);
		this.notifyChangeDate = this.notifyChangeDate.bind(this);
		this.onWorkshopAdmissionSubmitForm = this.onWorkshopAdmissionSubmitForm.bind(this);
		this.handleChangeDate = this.handleChangeDate.bind(this);
	}

	static propTypes = {
		/** Propiedad de función que se acciona para cambiar el estado del recaptcha. */
		changeReCaptchaStatus: PropTypes.func,
		/** Propiedad que indica el texto del título del formulario. */
		title: PropTypes.string,
		/** Propiedad que indica el texto del subtítulo del formulario. */
		subtitle: PropTypes.string,
		/** Propiedad que indica el id del switch */
		switchId: PropTypes.string,
		/** Propiedad que indica el id del input. */
		inputId: PropTypes.string,
		/** Propiedad que indica el placeholder del input. */
		placeholder: PropTypes.string,
		/** Propiedad que indica el numero de siniestro. */
		numSiniestro: PropTypes.string,
		/** Propiedad de función que se acciona cuando se pierde el foco del input. */
		onInputBlur: PropTypes.func,
		/** Propiedad de función que se acciona cuando se hace foco en el input. */
		onInputFocus: PropTypes.func,
		/** Propiedad que indica si el error del formulario se muestra. */
		showError: PropTypes.bool,
		/** Propiedad de función que se acciona cuando el valor del input cambia. */
		onInputChange: PropTypes.func,
		/** Propiedad que indica el texto que tendrá el tooltip. */
		tooltipMessage: PropTypes.string,
		/** Propiedad que indica el id del tooltip. */
		infoId: PropTypes.string,
		/** Propiedad de objeto contenedor de los estilos del recaptcha, estos estilos vienen del componenete padre. */
		recaptchaStyle: PropTypes.object,
		/** Propiedad indica el id que tendrá el boton de tercero en el switch. */
		workshopAdmissionButtonId: PropTypes.string,
		/** Propiedad de función que se acciona cuando se presiona el boton tercero en el switch. */
		onWorkshopAdmissionSubmit: PropTypes.func,
		/** Propiedad que indica el texto de terceros. */
		thirdText: PropTypes.string,
		/** Propiedad que indica el id que tendrá el boton de terceros. */
		thirdButtonId: PropTypes.string,
		/** Propiedad que indica el texto que tendrá el boton de terceros. */
		thirdButtonText: PropTypes.string,
		/** Propiedad de función que se acciona cuando se presiona el boton de terceros. */
		onThirdSubmit: PropTypes.func,
		/** Propiedad que indica el link del botón de terceros. */
		thirdButtonLink: PropTypes.string,
		/** Propiedad que contiene el mensaje a mostrar cuando los datos no son válidos */
		errorMessage: PropTypes.string,
		/** Propiedad que contiene el mensaje a mostrar cuando no se han ingresado datos */
		errorMessageEmptyInput: PropTypes.string,
		/** Propiedad que contiene la fecha de inicio del calendario */
		initialDate: PropTypes.string
	};

	state = {
		showError: false
	};

	notifyChangeDate(value) {
		console.log(value)
		this.setState({
			admissionDate: value,
			dateIsSelected: true,
			showMsgError: false
		});
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ reCaptchaLoad: true });
		}, process.env.REACT_APP_RECAPTCHA_DELAY);
	}

	onWorkshopAdmissionSubmitForm(event) {
		console.log('onWorkshopAdmissionSubmitForm');
		var admissionDate = this.state.admissionDate;
		console.log('admissionDate', admissionDate);
		console.log(typeof admissionDate);
		var noticeNumber = this.props.numSiniestro;
		var isValid = true;
		if(admissionDate == null || noticeNumber == null){
			let errorMessage = this.props.errorMessageEmptyInput;
			this.setState({
				showError: true,
				errorMessage: errorMessage 
			});
			isValid = false;
		} 
		if (admissionDate && admissionDate.getFullYear() && admissionDate.getFullYear()>9999){
			console.log('admissionDate > 9999');
			let errorMessage = this.props.errorMessage;
			this.setState({
				showError: true,
				errorMessage: errorMessage
			});
			isValid = false;
		}
		if (isValid){
			console.log('calling onWorkshopAdmissionSubmit')
			this.props.onWorkshopAdmissionSubmit(event, noticeNumber, admissionDate);
		}
	}


	calcDias() {
		let init = moment(this.props.initialDate);
		let end = moment();
		return end.diff(init, 'days');
	}

	handleChangeDate = (e) =>
	{
	console.log("evento-->",e)
	}

	render() {
		let styles_form_asegurado = '';
		let errorMessage = undefined;
		if (this.state.showError) {
			errorMessage = (
				<>
					<p>
						{this.state.errorMessage}
					</p>
				</>);
		}

		return (
			<form className={styles.form_consulta_siniestro}>
				<h2
					className={styles.form_consulta_siniestro_title}
					id="ingreso_form_title"
				>
					{this.props.title}
				</h2>
				<p
					className={styles.form_consulta_siniestro_info}
					id="ingreso_form_subtitle"
				>
					{this.props.info}
				</p>
				<p
					className={styles.form_consulta_siniestro_subtitle}
					id="ingreso_form_subtitle"
				>
					{this.props.subtitle}
				</p>
				<section className={styles.form_consulta_siniestro_cnt}>
					<section className={styles_form_asegurado}>
						
						<MaterialPicker {...{selectedDate:this.dateValue}} 
						onChange={day => {this.handleChangeDate(day)}} 
						notifyChangeDate={this.notifyChangeDate}
						/>
						<br></br><br></br>
						
						<Button
							id={this.props.workshopAdmissionButtonId}
							texto={this.props.workshopAdmissionButtonText}
							styles={styles.form_consulta_siniestro_cnt_asegurado_button}
							buttonType="primary_yellow"
							onClick={this.onWorkshopAdmissionSubmitForm}
							type="button"
						//type="submit"
						/>
						{errorMessage}
						<p>
							{this.props.footnote}
						</p>
					</section>
				</section>
			</form>
		);
	}
}

export default Form;
