import React, { Component } from 'react';
import { connect } from 'react-redux';
import styles from './table.module.scss';
import PropTypes from 'prop-types';
import { FaRegCheckSquare, FaRegSquare, FaExclamationCircle } from 'react-icons/fa';
import Modal from '../../../shared/modal/modal';
import i18next from '../../../config/i18next';
import * as actions from '../spareParts.actions';
import { LIMIT_TEXT_AREA } from '../../../config/constants';


class Table extends Component {
  WIDTH_SCREEN_MOBILE = 549;
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      modalReasonNoCheck: false,
      modalReasonConfirmCheck: false,
      itemSelectedAsNoCheck: undefined,
      modalTextError: undefined,
      modalTextAreaCount: 0,
      itemNoCheckReason: ""
    }
  }

  static propTypes = {
    /** Propiedad que contiene los items a mostrar por la tabla */
    items: PropTypes.array,
    /** Para indicar si marcar los repuestos no recepcionados */
    markNotReceived: PropTypes.bool,
    /** se ejecuta cuando el estado de un item cambia */
    changeOnitem: PropTypes.func
  };



  clickCheck = (item, index) => {
    if (item.receptionStatus === 1 && item.changed === undefined)  {
      return
    }
    this.setState({
      modalReasonConfirmCheck: true,
      itemSelectedForConfirm: {item: item, index: index}
    })
  }

  clickNo = (item, index) => {
    if (item.receptionStatus === 1 && item.changed === undefined) {
      return
    }
    console.log(item)
    let itemReason = item.changed ? item.reason : "";
    this.setState({
      itemNoCheckReason: itemReason,
      modalTextAreaCount: itemReason !== undefined ? itemReason.length : 0,
      modalReasonNoCheck: true,
      itemSelectedAsNoCheck: {item: item, index: index}
    })
  }


  createButtons(item, index) {
    let size = 30;
    let status = item.receptionStatus;
    let okIcon = undefined;
    let noOkIcon = undefined;
    let classIconOk = styles.table_icon + ' ' + styles.table_icon_ok;
    let classIconNoOk = styles.table_icon + ' ' + styles.table_icon_no_ok;

    if (status === 0 && this.showReason(item)) {
      noOkIcon = <FaRegCheckSquare size={size}/>;
      okIcon = <FaRegSquare size={size}/>;
    } else if (status === 1) {
      noOkIcon = <FaRegSquare size={size}/>;
      okIcon = <FaRegCheckSquare size={size}/>;
    } else {
      noOkIcon = <FaRegSquare size={size}/>;
      okIcon = <FaRegSquare size={size}/>;
    }

    return (
      <div className={styles.icon_container}>
        <div className={classIconOk}
          onClick={() => this.clickCheck(item, index)} >
          {okIcon}
          <div className={styles.table_icon_text_ok}>
            OK
          </div>
        </div>
        <div className={classIconNoOk}
          onClick={() => this.clickNo(item, index)} >
          {noOkIcon} 
          <div className={styles.table_icon_text_no_ok}>
            NO OK
          </div>
        </div>
      </div>
    )
  }


  createTableItem(item, i) {
    let class1 = styles.flex_table + ' ' + styles.row
    let classCode = styles.flex_table_row + ' ' + styles.flex_table_row_item_code
    let className = styles.flex_table_row + ' ' + styles.flex_table_row_item_name
    let classAmount = styles.flex_table_row + ' ' + styles.flex_table_row_item_amount
    let classCheck = styles.flex_table_row + ' ' + styles.flex_table_row_item_check
    let classReason = styles.flex_table_row + ' ' + styles.flex_table_row_item_reason
    let reasonCondition = this.showReason(item)
    return (
        <div className={class1} role="rowgroup" key={i}>
          <div className={classCheck} role="cell">
            {this.createButtons(item, i)}
          </div>
          <div className={classCode} role="cell">
            {item.code}
          </div>
          <div className={className} role="cell">
            {item.name}
          </div>
          <div className={classAmount} role="cell">
            {item.amount}
          </div>
          { reasonCondition &&
          <div className={classReason} role="cell">
            {item.reason}
          </div>
          }
        </div>
    )
  }

  showReason (item) {
    return item.receptionStatus === 0 && (this.props.markNotReceived || item.changed)
  }

  createTableItemMobile(item, i) {
    let class1 = styles.flex_table + ' ' + styles.row
    let className = styles.flex_table_row + ' ' + styles.flex_table_row_item_name
    let classCheck = styles.flex_table_row + ' ' + styles.flex_table_row_item_check
    return (
        <div className={class1} role="rowgroup" key={i}>
          <div className={classCheck} role="cell">
            {this.createButtons(item, i)}
          </div>
          <div className={className} role="cell">
            <div> {i18next.t('spareParts.table.columns.code')}: {item.code} </div> 
            <div> {item.name} </div> 
            <div> {i18next.t('spareParts.table.columns.amount')}: {item.amount} </div> 
          </div>
        </div>
    )
  }

  createTableHeader() {
    let class1 = styles.flex_table + ' ' + styles.flex_table_header
    let classCode = styles.flex_table_row + ' ' + styles.flex_table_row_item_code
    let className = styles.flex_table_row + ' ' + styles.flex_table_row_item_name
    let classAmount = styles.flex_table_row + ' ' + styles.flex_table_row_item_amount
    let classCheck = styles.flex_table_row + ' ' + styles.flex_table_row_item_check
    let classReason = styles.flex_table_row + ' ' + styles.flex_table_row_item_reason
    return( 
      <div className={class1} role="rowgroup">
        <div className={classCheck}>
          {i18next.t('spareParts.table.columns.check')}
        </div>
        <div className={classCode} >
          {i18next.t('spareParts.table.columns.code')}
        </div>
        <div className={className}>
          {i18next.t('spareParts.table.columns.name')}
        </div>
        <div className={classAmount}>
          {i18next.t('spareParts.table.columns.amount')}
        </div>
        <div className={classReason}>
          {i18next.t('spareParts.table.columns.reason')}
        </div>
      </div>
    )
  }

  createTableHeaderMobile() {
    let class1 = styles.flex_table + ' ' + styles.flex_table_header
    let className = styles.flex_table_row + ' ' + styles.flex_table_row_item_name
    let classCheck = styles.flex_table_row + ' ' + styles.flex_table_row_item_check
    return( 
      <div className={class1} role="rowgroup">
        <div className={classCheck}>
          {i18next.t('spareParts.table.columns.check')}
        </div>
        <div className={className} >
          {i18next.t('spareParts.table.columns.name')}
        </div>
      </div>
    )
  }
  saveReasonModal = () => {
    let index = this.state.itemSelectedAsNoCheck.index;
    let item = this.state.itemSelectedAsNoCheck.item;
    let reason = this.state.itemNoCheckReason;
    if (reason === undefined || reason === "") {
      this.setState({
        modalTextError: i18next.t('spareParts.modalReason.errorEmpty')
      })
      return;
    } 
    item.reason = reason;
    item.receptionStatus = 0;
    item.changed = true;
    if (this.props.changeOnItem) {
      this.props.changeOnItem(item)
    }
    this.props.updateSpareParts(item, index)

    this.setState({
      modalTextError: undefined,
      modalReasonNoCheck: false,
      itemSelectedAsNoCheck: undefined,
      itemNoCheckReason: ""
    });
  };

  modalConfirmCheck = () => {
    let index = this.state.itemSelectedForConfirm.index;
    let item = this.state.itemSelectedForConfirm.item;
    item.receptionStatus = 1;
    item.changed = true;
    item.reason = undefined;

    if (this.props.changeOnItem) {
      this.props.changeOnItem(item)
    }

    this.props.updateSpareParts(item, index)
    this.setState({
      modalReasonConfirmCheck: false,
      itemSelectedForConfirm: undefined,
      itemNoCheckReason: ""
    });
  }

  closeModal = () => {
    this.setState({
      modalReasonNoCheck: false,
      itemSelectedAsNoCheck: undefined,
      modalReasonConfirmCheck: false,
      itemSelectedForConfirm: undefined
    });
  };

  createModal() {
    let modal = undefined;
    let children = undefined;
    let itemSelected = this.state.itemSelectedAsNoCheck.item

    if (itemSelected) {

      children = (
        <div className = {styles.modal_container}>
          <div className = {styles.modal_icon}>
            <FaExclamationCircle size={30}/>
          </div>
          <div className = {styles.modal_item}>
            <p className = {styles.modal_item_selected} >
              {i18next.t('spareParts.modalReason.sparePartSelected')}
            </p>

            <p> {itemSelected.name} </p>
            <div  className = {styles.modal_item_reason}>
              <p>
                {i18next.t('spareParts.modalReason.reasonNoCheck')}
              </p>
            </div>
            <div>
              <textarea 
                autoFocus
                className = {styles.modal_item_input}
                type="text"
                onChange = {(e) => this.setState({
                  itemNoCheckReason: e.target.value,
                  modalTextError: undefined,
                  modalTextAreaCount: e.target.value.length
                })}
                maxLength = {LIMIT_TEXT_AREA}
                value = {this.state.itemNoCheckReason}
              >
              </textarea>
              <div className = {styles.modal_item_input_sub_text}>
                <p>{this.state.modalTextAreaCount}/{LIMIT_TEXT_AREA}</p>
              </div>
            </div>
            { this.state.modalTextError &&
            <div className = {styles.modal_error}>
              <p>
                {this.state.modalTextError}
              </p>
            </div>
            } 
          </div>
      </div>
      )
    }
    modal = (
      <Modal
        isVisible={true}
        submitModal={this.saveReasonModal}
        closeModal={this.closeModal}
        btnModalCloseIsVisible={true}
        blockTab = {true}
        buttonProps={{
          buttonId: 'liquidador_modal_button',
          submitButtonText: i18next.t('spareParts.modalReason.buttonSaveReason'),
          type: 'button'
        }}
      >
          {children}
      </Modal>
    )
    return modal;
  }

  createModalConfirm() {
    let itemSelected = this.state.itemSelectedForConfirm.item
    return (
      <Modal
        isVisible={true}
        submitModal={this.modalConfirmCheck}
        closeModal={this.closeModal}
        btnModalCloseIsVisible={false}
        onClickButtonNo={this.closeModal}
        flipButtons = {true}
        blockTab = {true}
        buttonProps={{
          buttonId: 'liquidador_modal_button',
          submitButtonText: i18next.t('spareParts.modalReason.buttonOk'),
          type: 'button'
        }}
        buttonNo = {{
          buttonId: 'button_no',
          text: i18next.t('spareParts.modalReason.buttonNo'),
          type: 'button'
        }}
      >
          <div className = {styles.modal_container}>
            <div className = {styles.modal_icon}>
              <FaExclamationCircle size={30}/>
            </div>
            <div className={styles.modal_item}>
              <p className={styles.modal_item_selected}>
                {i18next.t('spareParts.modalReason.confirmSelection')}
              </p>
              <p>
                {itemSelected.name}
              </p>
            </div>
          </div>
      </Modal>
    );
  }


  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions.bind(this));
  } 
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  updateDimensions() {
    this.setState({width: window.innerWidth});
  }

  isMobile() {
    if (window.innerWidth <= this.WIDTH_SCREEN_MOBILE) {
      return true;
    } 
    return false;
  }


  render() {
    let header = undefined;
    let items = undefined;
    let modalConfirm = undefined;
    let modal = undefined;

    if (this.isMobile()) {
      header = this.createTableHeaderMobile();
      items = this.props.items.map((item, i) => {
        return this.createTableItemMobile(item, i);
      })
    } else {
      header = this.createTableHeader();
      items = this.props.items.map((item, i) => {
        return this.createTableItem(item, i);
      })
    }


    if (this.state.modalReasonNoCheck) {
      modal = this.createModal();
    }
    if (this.state.modalReasonConfirmCheck) {
      modalConfirm = this.createModalConfirm();
    }

    return (
      <div>
        <div className={styles.table_container} role="table">
          {header}
          {items}
        </div>
        {modal}
        {modalConfirm}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    items: state.spareParts.items || []
  };
};

const mapDispatchToProps = dispatch => ({
  updateSpareParts: (newItem, index) =>
    dispatch(actions.updateSparePart(newItem, index)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Table);
