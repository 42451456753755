import React from "react";
import * as actions from "./callback.actions";
import * as actionsIngreso from "../ingreso/ingreso.actions";

import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import Loading from "../../shared/loading/loading";

const Callback = props => {
  const {
    getAccessTokenSilently,
    isLoading,
    loginWithRedirect,
    isAuthenticated
  } = useAuth0();

  React.useEffect(() => {
    if (isLoading) return;
    const handleCallback = async () => {
      try {
        if (!isAuthenticated) {
          await loginWithRedirect();
        }
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          }
        });

        props.redirectTracking(token);
      } catch (error) {
        throw new Error(error);
      }
    };
    handleCallback();
  }, [isLoading, loginWithRedirect, getAccessTokenSilently, isAuthenticated]);

  return <>{props.isFetching || isLoading ? <Loading /> : null}</>;
};

const mapStateToProps = state => {
  return {
    siniestro: state.callback.numSiniestro,
    timestamp: state.callback.timestamp,
    isFetching: state.callback.isFetching
  };
};

const mapDispatchToProps = dispatch => ({
  redirectTracking: userToken => dispatch(actions.redirectTracking(userToken)),
  consultarSiniestro: numeroSiniestro =>
  dispatch(actionsIngreso.consultarSiniestroDirecto(numeroSiniestro)),

});

export default connect(mapStateToProps, mapDispatchToProps)(Callback);
