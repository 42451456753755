import StepModel from '../steps/step/stepmodel';
import CONSTANTS from '../../../../config/constants';

class TheftTracking {
  siniestro = null;
  steps = [];

  checkStep = () => {
    if (this.siniestro.fechaCargaDeDocumentacion) {
      this.steps[1].setDate(this.siniestro.fechaCargaDeDocumentacion);
    }

    if (this.siniestro.fechaPreAnalisis) {
      this.steps[2].setDate(this.siniestro.fechaPreAnalisis);
    }

    if (
      this.siniestro.pendienteIndemnizacion &&
      this.siniestro.fechaPreAnalisis
    ) {
      this.steps[3].setStatus('pendingPayment');
    } else {
      this.steps[3].setStatus('pending');
    }

    if (
      (this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.CLOSED ||
        this.siniestro.fechaDePagoRobo) &&
      this.siniestro.codigoEstadoSiniestro !== CONSTANTS.CLAIM_STATUS.REJECTED
    ) {
      if (!this.siniestro.fechaCargaDeDocumentacion) {
        this.steps[1].setStatus('default');
      }

      if (!this.siniestro.fechaPreAnalisis) {
        this.steps[2].setStatus('default');
      }

      if (
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.CLOSED
      ) {
        this.siniestro.fechaDePagoRobo
          ? this.steps[3].setDate(this.siniestro.fechaDePagoRobo)
          : this.steps[3].setStatus('closed');
      } else if (
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.OPEN ||
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.REOPENED
      ) {
        this.siniestro.fechaDePagoRobo
          ? this.steps[3].setDate(this.siniestro.fechaDePagoRobo)
          : this.steps[3].setStatus('pending');
      }
    }

    if (
      this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.REJECTED
    ) {
      console.log('this.steps: ', this.steps);
      this.steps[2].setDate(null);
      this.steps[2].setStatus('default');
      this.steps[2].setSubsteps([]);
      this.steps[3].setDate(null);
      this.steps[3].setStatus('rejected');
      console.log('this.steps: ', this.steps);
    }
  };

  constructor(siniestro) {
    this.siniestro = siniestro;

    this.steps = [
      new StepModel(
        'ingreso',
        'approved',
        this.siniestro.fechaRegistroImplicado,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'documentos',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'analisis',
        'pending',
        null,
        this.siniestro.numeroSiniestro,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'entrega',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      )
    ];

    this.checkStep();
  }
}

export default TheftTracking;
