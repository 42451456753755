export const ACTION_TYPES = {
  SHOW_WHATSAPP_ICON: 'SHOW_WHATSAPP_ICON'
};

const initialState = {
  whatsappVisibility: true
};

export default (state = initialState, action) => {
  if(action.type === ACTION_TYPES.SHOW_WHATSAPP_ICON){
    return {
      ...state,
      whatsappVisibility: action.isVisible
    } 
  }
  return state;
};
