import PropTypes from 'prop-types';
import React, { Component } from 'react';
import i18next from '../../config/i18next';
import Modal from '../modal/modal';
import './brandContact.scss';
import ReactGA from 'react-ga';

class BrandContact extends Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  static propTypes = {
    /** Propiedad que indica si el tamaño de la ventana equivale a mobile. */
    isMobile: PropTypes.bool,
    /** Propiedad que indica si el footer puede verse en la página. */
    footerIsVisible: PropTypes.bool
  };

  gtag = window.gtag;

  state = {
    showModal: false
  };

  innerHTMLHelpTextStrong = () => {
    return { __html: i18next.t('shared.contact.helpTextStrong') };
  };

  innerHTMLWhatsappHorario = () => {
    return { __html: i18next.t('shared.contact.whatsappHorario') };
  };

  whatsappLink = this.props.isMobile
    ? i18next.t('shared.contact.whatsappLinkMobile')
    : i18next.t('shared.contact.whatsappLinkDesktop');

  whatsappHandler = event => {
    ReactGA.event({
      category: 'contacto',
      action: 'click',
      label: 'whatsapp',
    });
    if (this.props.isMobile) {
      event.preventDefault();
      this.toggleModal();
    }
  };

  phoneHandler = () => {
    ReactGA.event({
      category: 'contacto',
      action: 'click',
      label: 'call',
    });
  };

  toggleModal = () => {
    this.setState({
      showModal: !this.state.showModal
    });
  };

  modalClickHandler = () => {
    this.toggleModal();
  };

  render() {
    let { backgroundColor, fontColor, visible } = this.props.brand.style.contactArea;
    if (this.state.showModal) {
      this.modal = (
        <Modal
          isVisible={true}
          closeModal={this.toggleModal}
          submitModal={this.modalClickHandler}
          btnModalCloseIsVisible={true}
          buttonProps={{
            buttonId: 'contacto_modal_button',
            submitButtonIcon: 'icon-whatsapp',
            submitButtonText: i18next.t('ingreso.modal.submitButton'),
            type: 'link',
            href: this.whatsappLink,
            noTarget: true
          }}
        >
          <section className="modal_content">
            <h4 className="modal_content_title">
              {i18next.t('shared.contact.whatsappHorarioTitle')}
            </h4>
            <p
              className="modal_content_text"
              dangerouslySetInnerHTML={this.innerHTMLWhatsappHorario()}
            />
          </section>
        </Modal>
      );
    } else {
      this.modal = null;
    }

    const contact_styles = this.props.footerIsVisible
      ? 'contact'
      : 'contact contact--fixed';
    return (

      <>
        {this.modal}

        {visible &&
          <section className={contact_styles}>
            <article className="contact_item">
              <section className="whatsapp_detail">
                <h4 className="whatsapp_detail_title">
                  {i18next.t('shared.contact.whatsappHorarioTitle')}
                </h4>
                <p
                  className="whatsapp_detail_text"
                  dangerouslySetInnerHTML={this.innerHTMLWhatsappHorario()}
                />
              </section>
              <a
                href={this.whatsappLink}
                id="contact_whatsapp_icon"
                target="_blank"
                rel="noopener noreferrer"
                onClick={this.whatsappHandler}
              >
                <span style={{ backgroundColor: backgroundColor, color: fontColor }} className="contact_item_icon icon-whatsapp pulse" />
              </a>
            </article>

            <article className="contact_item">
              <section className="contact_item_text">
                <strong
                  className="strong"
                  dangerouslySetInnerHTML={this.innerHTMLHelpTextStrong()}
                />
                <div className="text">{i18next.t('shared.contact.helpText')}</div>
              </section>
              <a
                href={'tel:' + i18next.t('shared.contact.contactTel')}
                id="contact_phone_number"
                onClick={this.phoneHandler}
              >
                <span style={{ backgroundColor: backgroundColor, color: fontColor }} className="contact_item_icon icon-phone" />
              </a>
            </article>
          </section>
        }
      </>
    );
  }
}

export default BrandContact;
