export const ACTION_TYPES = {
  CONSULTAR_SINIESTRO_REQUEST: 'ingreso/CONSULTAR_SINIESTRO_REQUEST',
  CONSULTAR_SINIESTRO_SUCCESS: 'ingreso/CONSULTAR_SINIESTRO_SUCCESS',
  CONSULTAR_SINIESTRO_FAILURE: 'ingreso/CONSULTAR_SINIESTRO_FAILURE',
  SALVAR_CONTEXTO: 'ingreso/SALVAR_CONTEXTO_SUCCESS',
  COMUNICAR_ERROR: 'ingreso/COMUNICAR_ERROR',
};

const initialState = {
  siniestro: { paso: 2, isFetching: false },
  error: { codigo: '', mensaje: '' }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CONSULTAR_SINIESTRO_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ACTION_TYPES.CONSULTAR_SINIESTRO_SUCCESS:
      return {
        ...state,
        isFetching: false,
        siniestro: action.siniestro
      };
    case ACTION_TYPES.CONSULTAR_SINIESTRO_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error
      };
    case ACTION_TYPES.SALVAR_CONTEXTO:
      return {
        ...state,
        isFetching: true
      }
    default:
      return state;
  }
};
