import React, { Component } from 'react';
import Button from '../../../../../shared/button/button';
import SubSteps from './substeps/substeps';
import styles from './step.module.scss';
import CONSTANTS from '../../../../../config/constants';
import PropTypes from 'prop-types';
import * as actions from './step.actions';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip'
import i18next from '../../../../../config/i18next';
import ReactGA from 'react-ga';

class Step extends Component {
  constructor(props) {
    super();
    this.props = props;
    this.figCaption = null;
    this.checkElementHidden();
    this.checkStepStatus();
    this.setElements();
  }

  static propTypes = {
    /** Propiedad que indica el estado del siniestro. */
    claimStatus: PropTypes.string,
    /** Propiedad que indica la etapa del siniestro. */
    step: PropTypes.string,
    /** Propiedad que indica el texto de ayuda de la etapa del siniestro. */
    helpText: PropTypes.string,
    /** Propiedad que indica el estado de la etapa. */
    status: PropTypes.string,
    /** Propiedad que indica la categoria del evento. */
    eventCategory: PropTypes.string,
    /** Propiedad que indica el label del evento. */
    eventLabel: PropTypes.string,
    /** Propiedad que indica el texto del botón. */
    buttonText: PropTypes.string,
    /** Propiedad que indica el link del botón. */
    buttonLink: PropTypes.string,
    /** Propiedad que indica las subetapas de la etapa. */
    substeps: PropTypes.array,
    /** Propiedad que indica el id de la etapa. */
    id: PropTypes.string,
    /** Propiedad que indica la fecha de la etapa. */
    date: PropTypes.string,
    /** Propiedad que indica la imágen de la etapa. */
    image: PropTypes.string,
    /** Propiedad que indica la descripción de la imagen. */
    altImage: PropTypes.string,
    /** Propiedad que indica texto del label de la etapa. */
    label: PropTypes.string,
    /** Propiedad que indica el status de la categoria del siniestro */
    claimCategory: PropTypes.number
  };

  gtag = window.gtag;

  checkElementHidden() {
    this.hideButton =
      (this.props.claimStatus === CONSTANTS.CLAIM_STATUS.REJECTED &&
        this.props.step !== 'analisis') || !this.props.buttonText;
    this.hideHelpText =
      (this.props.claimStatus === CONSTANTS.CLAIM_STATUS.REJECTED &&
        this.props.step !== 'analisis') ||
      !this.props.helpText;

  }

  checkStepStatus() {
    switch (this.props.status) {
      case 'approved':
        this.figCaption = (
          <figcaption
            className={
              styles.figure_caption + ' ' + styles.figure_caption_approved
            }
          />
        );
        break;

      case 'pending':
        this.figCaption = (
          <figcaption
            className={
              styles.figure_caption + ' ' + styles.figure_caption_pending
            }
          />
        );
        break;

      case 'pendingPayment':
        this.figCaption = (
          <figcaption
            className={
              styles.figure_caption + ' ' + styles.figure_caption_pendingPayment
            }
          />
        );
        break;

      case 'rejected':
        this.figCaption = (
          <figcaption
            className={
              styles.figure_caption + ' ' + styles.figure_caption_rejected
            }
          />
        );
        break;

      default:
        break;
    }
  }

  buttonHandler = (event) => {
    event.preventDefault();
    console.log('step.js.buttonHandler - clamNumber:[], step: []', this.props.claimNumber, this.props.step, this.props.claimCategory);
    ReactGA.event({
      category: this.props.eventCategory,
      action: 'click',
      label: this.props.eventLabel,
    });
    this.changeToWorkshopAdmissionAction(this.props.claimNumber, this.props.step, this.props.claimCategory);
  };

  changeToWorkshopAdmissionAction = (claimNumber, step, claimCategory) => {
    this.props.changeToWorkshopAdmission(claimNumber, step, claimCategory);
  };


  setElements() {
    this.button = this.hideButton ? null : (
    //this.button = (
      <Button
        buttonType="primary_yellow"
        styles={styles.button}
        texto={this.props.buttonText}
        href={this.props.buttonLink}
        type="sumbit"
        onClick={this.buttonHandler}
        id={'step_' + this.props.step + '_button'}
        noTarget={true}
      />
    );
    this.helpText = this.hideHelpText ? null : (
      <p className={styles.helpText}>{this.props.helpText}</p>
    );

    this.substeps =
      this.props.substeps.length > 0 ? (
        <section className={styles.substepInfo}>
          <SubSteps substeps={this.props.substeps} />
        </section>
      ) : null;
  }

  render() {
    return (
      <article className={styles.container} id={this.props.id}>
        <p className={styles.date}>{this.props.date}</p>
        <section className={styles.figure_container}>
          <figure className={styles.figure}>
            {this.figCaption}
            <img
              className={styles.figure_img}
              src={this.props.image}
              alt={this.props.altImage}
            />
          </figure>
        </section>
        <label className={styles.label}>{this.props.label}
          {this.props.step === 'documentos' &&
            <span className={styles.span_tooltip}>
              <span data-tip={i18next.t('tracking.tooltipDocumentos')} className={styles.tooltip_documentos} data-event='click focus'>
                <span className={styles.icon + ' icon-info'} /></span>
              <ReactTooltip className={styles.tooltip_documentos} place="bottom" globalEventOff='click' />
            </span>
          }
          {this.props.status === 'pendingPayment' && this.props.claimCategory ===CONSTANTS.CLAIM_TYPE.TOTAL &&
            <span className={styles.span_tooltip}>
              <span data-tip={i18next.t('tracking.tooltipEntregaInfo')} className={styles.tooltip_documentos} data-event='click focus'>
                <span className={styles.icon + ' icon-info'} />
              </span>
              <ReactTooltip className={styles.tooltip_documentos} place="bottom" globalEventOff='click' />
            </span>
          }
        </label>
        {this.helpText}
        {this.button}
        {this.substeps}
      </article>
    );
  }
}


const mapStateToProps = state => {
  return {
  };
};


const mapDispatchToProps = dispatch => ({
  changeToWorkshopAdmission: (numeroSiniestro, step, claimCategory) =>
    dispatch(actions.changeToWorkshopAdmission(numeroSiniestro, step, claimCategory))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Step);