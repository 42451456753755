import StepModel from '../steps/step/stepmodel';
import CONSTANTS from '../../../../config/constants';

class ExpressTracking {
  siniestro = null;
  steps = [];

  checkStep = () => {
    if (this.siniestro.fechaPreAnalisis) {
      this.steps[1].setDate(this.siniestro.fechaPreAnalisis);
    }

    if (this.siniestro.fechaIngresoATaller) {
      this.steps[2].setDate(this.siniestro.fechaIngresoATaller);
    }

    if (
      this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.CLOSED ||
      this.siniestro.fechaEntregaReal
    ) {
      if (!this.siniestro.fechaPreAnalisis) {
        this.steps[1].setStatus('default');
      }

      if (!this.siniestro.fechaIngresoATaller) {
        this.steps[2].setStatus('default');
      }

      if (
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.CLOSED
      ) {
        this.siniestro.fechaEntregaReal
          ? this.steps[3].setDate(this.siniestro.fechaEntregaReal)
          : this.steps[3].setStatus('default');
      } else if (
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.OPEN ||
        this.siniestro.codigoEstadoSiniestro === CONSTANTS.CLAIM_STATUS.REOPENED
      ) {
        this.siniestro.fechaEntregaReal
          ? this.steps[3].setDate(this.siniestro.fechaEntregaReal)
          : this.steps[3].setStatus('pending');
      }
    }
    if (this.siniestro.tallerImplicado===undefined) {
      this.steps[2].setStatus('default');
    }
  };

  constructor(siniestro) {
    this.siniestro = siniestro;

    this.steps = [
      new StepModel(
        'ingreso',
        'approved',
        this.siniestro.fechaRegistroImplicado,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'analisis',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'taller',
        'pending',
        null,
        this.siniestro.numeroSiniestro,
        this.siniestro.categoriaSiniestro
      ),
      new StepModel(
        'entrega',
        'pending',
        null,
        null,
        this.siniestro.categoriaSiniestro
      )
    ];

    this.checkStep();
  }
}

export default ExpressTracking;
