import React, { Component } from 'react';
import Button from '../../../shared/button/button';
import styles from './message.module.scss';
import PropTypes from 'prop-types';

class Message extends Component {
	constructor(props) {
		super(props);
		this.props = props;
		this.notifyChangeDate = this.notifyChangeDate.bind(this);
		this.onWorkshopAdmissionSubmitForm = this.onWorkshopAdmissionSubmitForm.bind(this);
	}

	static propTypes = {
		/** Propiedad de función que se acciona para cambiar el estado del recaptcha. */
		changeReCaptchaStatus: PropTypes.func,
		/** Propiedad que indica el texto del título del formulario. */
		title: PropTypes.string,
		/** Propiedad que indica el texto del subtítulo del formulario. */
		subtitle: PropTypes.string,
		/** Propiedad que indica el id del switch */
		switchId: PropTypes.string,
		/** Propiedad que indica el id del input. */
		inputId: PropTypes.string,
		/** Propiedad que indica el numero de siniestro. */
		numSiniestro: PropTypes.string,
		/** Propiedad de función que se acciona cuando se pierde el foco del input. */
		onInputBlur: PropTypes.func,
		/** Propiedad de función que se acciona cuando se hace foco en el input. */
		onInputFocus: PropTypes.func,
		/** Propiedad que indica si el error del formulario se muestra. */
		showError: PropTypes.bool,
		/** Propiedad de función que se acciona cuando el valor del input cambia. */
		onInputChange: PropTypes.func,
		/** Propiedad que indica el texto que tendrá el tooltip. */
		tooltipMessage: PropTypes.string,
		/** Propiedad que indica el id del tooltip. */
		infoId: PropTypes.string,
		/** Propiedad de objeto contenedor de los estilos del recaptcha, estos estilos vienen del componenete padre. */
		recaptchaStyle: PropTypes.object,
		/** Propiedad indica el id que tendrá el boton de tercero en el switch. */
		workshopAdmissionButtonId: PropTypes.string,
		/** Propiedad de función que se acciona cuando se presiona el boton tercero en el switch. */
		onWorkshopAdmissionSubmit: PropTypes.func,
		/** Propiedad que indica el texto de terceros. */
		thirdText: PropTypes.string,
		/** Propiedad que indica el id que tendrá el boton de terceros. */
		thirdButtonId: PropTypes.string,
		/** Propiedad que indica el texto que tendrá el boton de terceros. */
		thirdButtonText: PropTypes.string,
		/** Propiedad de función que se acciona cuando se presiona el boton de terceros. */
		onThirdSubmit: PropTypes.func,
		/** Propiedad que indica el link del botón de terceros. */
		thirdButtonLink: PropTypes.string
	};

	state = {
		reCaptchaValue: '',
		reCaptchaLoad: false,
		reCaptchaExpired: false,
	};

	notifyChangeDate(value) {
		this.setState({
			admissionDate: value,
			dateIsSelected: true,
			showMsgError: false
		});
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ reCaptchaLoad: true });
		}, process.env.REACT_APP_RECAPTCHA_DELAY);
	}

	onWorkshopAdmissionSubmitForm(event) {
		console.log('onWorkshopAdmissionSubmitForm');
		this.props.onWorkshopAdmissionSubmit();
		// var url = '/siniestros/' + noticeNumber + '/workshop-admission' 
		// console.log('axios.post '+ url);
		// axios.post(url,
		// 	{
		// 		admissionDate: admissionDate
		// 	}
		// ).then(response => {
		// 	console.log('onWorkshopAdmissionSubmitForm.response', JSON.stringify(response));
		// 	if (
		// 		response.data.status === CONSTANTS.STATUS_CODE.OK &&
		// 		response.data.responseBody.categoriaSiniestro !==
		// 		CONSTANTS.CLAIM_TYPE.THIRD
		// 	) {
		// 		//dispatch(setSiniestro(response.data.responseBody));
		// 		//dispatch(comunicarError({ codigo: '', mensaje: '' }));
		// 		//history.push(`/siniestro/${noticeNumber}`);
		// 	} else {
		// 		//dispatch(setSiniestro({}));
		// 		//dispatch(
		// 		//	comunicarError({
		// 		//		codigo: response.data.status,
		// 		//		mensaje: response.data.errorMessage
		// 		//	})
		// 		//);

		// 	}
		// })
		// .catch(error => {
		// 	console.log('onWorkshopAdmissionSubmitForm.error', JSON.stringify(error));
		// 	//dispatch(setSiniestro({}));
		// 	history.push('/error');
		// });		
	}

	render() {
		let styles_form_asegurado = '';

		return (
			<form className={styles.form_consulta_siniestro}>
				<h2
					className={styles.form_consulta_siniestro_title}
					id="ingreso_form_title"
				>
					{this.props.title}
				</h2>
				<p
					className={styles.form_consulta_siniestro_info}
					id="ingreso_form_subtitle"
				>
					{this.props.info}
				</p>
				<section className={styles.form_consulta_siniestro_cnt}>
					<section className={styles_form_asegurado}>
						<br />
						<Button
							id={this.props.workshopAdmissionButtonId}
							texto={this.props.workshopAdmissionButtonText}
							styles={styles.form_consulta_siniestro_cnt_asegurado_button}
							buttonType="primary_yellow"
							onClick={this.onWorkshopAdmissionSubmitForm}
						//type="submit"
						/>
					</section>
				</section>
			</form>
		);
	}
}

export default Message;
