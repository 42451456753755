import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.scss";
import App from "./app/App";
import * as serviceWorker from "./serviceWorker";
import initStore from "./app/config/store";
import { Auth0Provider } from "@auth0/auth0-react";

const config = {
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
  clientId: process.env.REACT_APP_AUTH0_CLIENTID,
  authorizationParams: {
    redirect_uri: window.location.origin + "/callback"
  }
};

const store = initStore();

ReactDOM.render(
  <Auth0Provider {...config}>
    <Provider store={store}>
      <App />
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
