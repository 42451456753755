const CONSTANTS = {
  STATUS_CODE: {
    OK: 200,
    BAD_REQUEST: 400,
    NOT_FOUND: 404
  },
  CLAIM_STATUS: {
    OPEN: '0',
    CLOSED: '1',
    CANCELED: '2',
    REJECTED: '3',
    REOPENED: '4',
    PREDICT: '5'
  },
  CLAIM_TYPE: {
    PARTIAL: '0',
    EXPRESS: '1',
    THEFT: '2',
    TOTAL: '3',
    THIRD: '4'
  },
  WINDOW_SIZES: {
    TABLET: 768
  },
  ADJUSTER_TYPE: {
    OFICIAL: 'Oficial',
    DIRECT: 'Directo'
  },
};

export const QR_IDENTIFIER = '#repuestos';

export const URL_ALIRO = 'localhost';
export const PRODUCT_ALIRO = 1101;
export const PRODUCT_ALIRO_2 = 1118;
export const BRAND_ERROR = 'bad_brand'

export const LIBERTY_ANALITYCS_KEY = 'UA-47848886-21';
export const ALIRO_ANALITYCS_KEY = 'UA-47848886-32';

export const ITEMS = {
  INSURED: {
    id: 0,
    name: "Asegurado"
  }, 
  THIRD: {
    id: 1,
    name: "Tercero"
  }, 
  SPARE_PARTS: {
    id: 2,
    name: "Taller"
  }
}
export const LIMIT_TEXT_AREA = 100;

export const URL_TRACKING = process.env.REACT_APP_BASE_API_URL;

export const URL_SESSION_CONTEXT = process.env.REACT_APP_SESSION_CONTEXT_API_URL;

export const URL_SPARE_PART = process.env.REACT_APP_BASE_API_URL_SPARE_PARTS;

export const REDIRECT = {
  ALIRO : process.env.REACT_APP_REDIRECT_ALIRO,
  LIBERTY: process.env.REACT_APP_REDIRECT_LIBERTY
}

export default CONSTANTS;


