export const ACTION_TYPES = {
  CONSULTAR_REPUESTOS_REQUEST: 'repuestos/CONSULTAR_REPUESTOS_REQUEST',
  CONSULTAR_REPUESTOS_SUCCESS: 'repuestos/CONSULTAR_REPUESTOS_SUCCESS',
  CONSULTAR_REPUESTOS_FAILURE: 'repuestos/CONSULTAR_REPUESTOS_FAILURE',
  UPDATE_SPARE_PARTS: 'repuestos/UPDATE_SPARE_PARTS',
  SET_SPARE_PARTS: 'repuestos/SET_SPARE_PARTS',
  CLEAN_ERROR_SAVE: 'repuestos/CLEAN_ERROR_SAVE',
  GUARDAR_RECEPCION_REQUEST: 'repuestos/GUARDAR_RECEPCION_REQUEST',
  GUARDAR_RECEPCION_SUCESS: 'repuestos/GUARDAR_RECEPCION_SUCESS',
  GUARDAR_RECEPCION_FAILURE: 'repuestos/GUARDAR_RECEPCION_FAILURE',
};

const initialState = {
  error: { codigo: '', mensaje: '' },
  request: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.CONSULTAR_REPUESTOS_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: action.error
      };
    case ACTION_TYPES.CONSULTAR_REPUESTOS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        request: action.request
      };
    case ACTION_TYPES.CONSULTAR_REPUESTOS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    case ACTION_TYPES.UPDATE_SPARE_PARTS:
      let newItems = state.items;
      newItems[action.index] = action.newItem;
      return {
        ...state,
        items: newItems
      };

    case ACTION_TYPES.SET_SPARE_PARTS:
      return {
        ...state,
        items: action.items,
      };


    case ACTION_TYPES.GUARDAR_RECEPCION_REQUEST:
      return {
        ...state,
        isFetching: true
      };
    case ACTION_TYPES.GUARDAR_RECEPCION_SUCESS:
      return {
        ...state,
        isFetching: false,
        result: action.result
      };
    case ACTION_TYPES.GUARDAR_RECEPCION_FAILURE:
      return {
        ...state,
        isFetching: false,
        result: action.result,
      };
    case ACTION_TYPES.CLEAN_ERROR_SAVE:
      return {
        ...state,
        result: action.result
      };
    default:
      return state;
  }
};
