export const ACTION_TYPES = {
	SAVE_WORKSHOP_ADMISSION_REQUEST: 'workshopAdmission/SAVE_WORKSHOP_ADMISSION_REQUEST',
	SAVE_WORKSHOP_ADMISSION_SUCCESS: 'workshopAdmission/SAVE_WORKSHOP_ADMISSION_SUCCESS',
	SAVE_WORKSHOP_ADMISSION_FAILURE: 'workshopAdmission/SAVE_WORKSHOP_ADMISSION_FAILURE',
	COMUNICAR_ERROR: 'workshopAdmission/COMUNICAR_ERROR'
};

const initialState = {
	siniestro: { paso: 2, isFetching: false },
	error: { codigo: '', mensaje: '' }
};

export default (state = initialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.SAVE_WORKSHOP_ADMISSION_REQUEST:
			return {
				...state,
				isFetching: true
			};
		case ACTION_TYPES.SAVE_WORKSHOP_ADMISSION_SUCCESS:
			return {
				...state,
				isFetching: false,
				siniestro: action.siniestro
			};
		case ACTION_TYPES.SAVE_WORKSHOP_ADMISSION_FAILURE:
			return {
				...state,
				isFetching: false,
				error: action.error
			};
		default:
			return state;
	}
};
