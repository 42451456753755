import React, { Component } from 'react';
import { connect } from 'react-redux';
import i18next from '../../config/i18next';
import CONSTANTS, { QR_IDENTIFIER, BRAND_ERROR } from '../../config/constants';
import * as actions from './ingreso.actions';
import * as actionsSpareParts from '../spareParts/spareParts.actions';
import * as actionsApp from '../../App.actions';
import styles from './ingreso.module.scss';
import Form from './form/form';
import Modal from '../../shared/modal/modal';
import Loading from '../../shared/loading/loading';
import { checkIfMobile } from '../../shared/functions/utils';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import history from '../../config/history';


const verifyAliro = () => {
  let urlName = window.location.hostname;
  if (urlName === process.env.REACT_APP_URL_ALIRO) {
    return true;
  }
  return false;
}

class Ingreso extends Component {
  isMobile = false;
  gtag = window.gtag;


  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.isMobile = checkIfMobile();

    if (this.props.location.hash) {
      this.isFromQR = this.props.location.hash === QR_IDENTIFIER
    }

    if (this.isFromQR && !verifyAliro()) {
      this.props.whatsappVisibility(false)
    } else if (this.isFromQR && verifyAliro()) {
      history.replace('/');
      this.isFromQR = false;
    }
  }

  static propTypes = {
    /** Propiedad de función que se acciona para consultar el siniestro. */
    consultarSiniestro: PropTypes.func,
    /** Propiedad de función que se acciona para indicar que hubo un error en la app. */
    comunicarError: PropTypes.func,

    setError: PropTypes.func,
    /** Propiedad que indica el error ocurrido en la app. */
    error: PropTypes.object,
    /** Propiedad que indica si el servicio rest aún está trabajando */
    isFetching: PropTypes.bool
  };

  state = {
    modalIsVisible: false,
    numSiniestro: '',
    numGuia: '',
    showFormError: false,
    showFormErrorGuideNumber: false,
    formIsValid: false,
    formIsValidGuideNumber: false,
    reCaptchaIsValid: false,
    errorMessage: i18next.t('ingreso.form.errorMessage'),
    sessionTimestamp: new Date()
  };

  whatsappLink = this.isMobile
    ? i18next.t('shared.contact.whatsappLinkMobile')
    : i18next.t('shared.contact.whatsappLinkDesktop');

  image = require('../../../assets/img/banner-img.jpg');
  image2x = require('../../../assets/img/banner-img@2x.jpg');

  consultarSiniestroAction = numeroSiniestro => {
    this.props.consultarSiniestro(numeroSiniestro);
  };
  consultarRepuestosAction = (numeroSiniestro, numeroGuia) => {
    this.props.consultarRepuestos(numeroSiniestro, numeroGuia);
  };
  salvarContextoAction = (timestamp, siniestro) => {
    this.props.salvarContexto(timestamp, siniestro);
  };

  modalClickHandler = () => {
    console.log('Presionando botón Aceptar Modal');
    this.props.comunicarError({ codigo: '', mensaje: '' });
    this.setState({
      numSiniestro: '',
      numGuia: ''
    });
  };

  modalClickHandlerSparePart = () => {
    console.log('Presionando botón Aceptar Modal');
    this.props.setErrorSpareParts({ showModal: false, message: '' });
  };

  modalBrandClickHandler = () => {
    console.log('Presionando botón Aceptar Modal');
    this.props.comunicarError({ codigo: '', mensaje: '' });
    this.props.salvarContexto(new Date().getTime(), this.props.siniestro);
  };

  modalBrandCloseHandler = () => {
    this.props.comunicarError({ codigo: '', mensaje: '' });
  }

  changeReCaptchaStatusHandler = status => {
    this.setState({
      reCaptchaIsValid: status
    });
    status
      ? this.setState({
        showFormError: false
      })
      : this.setState({
        showFormError: true
      });
  };

  inputBlurHandler = () => {
    console.log('Ejecutando inputBlurHandler');
    if (this.state.numSiniestro === '') {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: true,
          errorMessage: i18next.t('ingreso.form.errorMessageEmptyInput')
        };
      });
    } else if (
      this.state.numSiniestro.length < 7 &&
      this.state.numSiniestro.length > 0
    ) {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: true,
          errorMessage: i18next.t('ingreso.form.errorMessage')
        };
      });
    } else if (this.state.numSiniestro && this.state.numSiniestro.length >= 7) {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: false
        };
      });
    }
  };

  inputFocusHandler = () => {
    this.setState(previusState => {
      return {
        showFormError: false,
        showFormErrorGuideNumber: false
      };
    });
  };

  inputChangeHandler = event => {
    const value = event.target.value.replace(/\D/g, '');
    this.setState(previusState => {
      return {
        numSiniestro: value
      };
    });
    if (value && value.length >= 7) {
      this.setState(previusState => {
        return {
          formIsValid: true
        };
      });
    } else if (value && value.length < 7) {
      this.setState(previusState => {
        return {
          formIsValid: false
        };
      });
    }
  };

  inputChangeHandlerGuideNumber = event => {
    const value = event.target.value.replace(/\D/g, '');
    this.setState(previusState => {
      return {
        numGuia: value
      };
    });
    if (value && value.length >= 7) {
      this.setState(previusState => {
        return {
          formIsValidGuideNumber: true
        };
      });
    } else if (value && value.length < 7) {
      this.setState(previusState => {
        return {
          formIsValidGuideNumber: false
        };
      });
    }
  };
  thirdSubmitHandler;

  formSubmitHandler = event => {
    event.preventDefault();
    ReactGA.event({
      category: 'busqueda',
      action: 'click',
      label: 'consulta_siniestro',
    });
    if (this.state.numSiniestro === '') {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: true,
          errorMessage: i18next.t('ingreso.form.errorMessageEmptyInput')
        };
      });
    } else if (
      this.state.numSiniestro.length === 8 ||
      this.state.numSiniestro.length === 9
    ) {
      this.props.comunicarError({
        codigo: CONSTANTS.STATUS_CODE.BAD_REQUEST,
        mensaje: i18next.t('ingreso.actions.errors.badRequest')
      });
    } else if (
      this.state.numSiniestro.length < 7 &&
      this.state.numSiniestro.length > 0
    ) {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: true,
          errorMessage: i18next.t('ingreso.form.errorMessage')
        };
      });
    } else if (
      this.state.numSiniestro.length === 7 ||
      this.state.numSiniestro.length === 10
    ) {
      // comprobación de recaptcha
      if (!this.state.reCaptchaIsValid) {
        this.setState(previusState => {
          return {
            showFormError: true,
            errorMessage: i18next.t('ingreso.form.errorMessageReCaptcha')
          };
        });
      } else {
        this.setState(previusState => {
          return {
            showFormError: false,
            errorMessage: i18next.t('ingreso.form.errorMessage')
          };
        });
        this.consultarSiniestroAction(this.state.numSiniestro);
      }
    }
  };

  formSubmitHandlerWorkshop = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'busqueda',
      action: 'click',
      label: 'consulta_repuestos',
    });
    if (this.state.numSiniestro === '') {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: true,
          errorMessage: i18next.t('ingreso.form.errorMessageEmptyInput')
        };
      });
    }

    if (this.state.numGuia === '') {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormErrorGuideNumber: true,
          guideNumberErrorMessage: i18next.t('ingreso.form.errorMessageEmptyInputGuideNumber')
        };
      });
    } else if (
      this.state.numSiniestro.length === 8 ||
      this.state.numSiniestro.length === 9
    ) {
      this.props.comunicarError({
        codigo: CONSTANTS.STATUS_CODE.BAD_REQUEST,
        mensaje: i18next.t('ingreso.actions.errors.badRequest')
      });
    } else if (
      this.state.numSiniestro.length < 7 &&
      this.state.numSiniestro.length > 0
    ) {
      this.setState(previusState => {
        return {
          ...previusState,
          showFormError: true,
          errorMessage: i18next.t('ingreso.form.errorMessage')
        };
      });
    } else if (
      this.state.numSiniestro.length === 7 ||
      this.state.numSiniestro.length === 10
    ) {
      // comprobación de recaptcha
      if (!this.state.reCaptchaIsValid) {
        this.setState(previusState => {
          return {
            showFormErrorGuideNumber: true,
            guideNumberErrorMessage: i18next.t('ingreso.form.errorMessageReCaptcha')
          };
        });
      } else {
        this.setState(previusState => {
          return {
            showFormError: false,
            errorMessage: i18next.t('ingreso.form.errorMessage')
          };
        });
        this.consultarRepuestosAction(this.state.numSiniestro,
          this.state.numGuia);
      }
    }
  };

  createSparePartErrorModal(message, buttonMessage, buttonExit) {
    if (buttonExit === undefined) {
      buttonExit = true
    }
    return (
      <Modal
        closeModalId="ingreso_modal_close_button"
        isVisible={true}
        submitModal={this.modalClickHandlerSparePart}
        closeModal={this.modalClickHandlerSparePart}
        btnModalCloseIsVisible={buttonExit}
        buttonProps={{
          buttonId: 'ingreso_modal_button',
          //submitButtonText: i18next.t('spareParts.modal.goBack'),
          submitButtonText: buttonMessage,
          type: 'link'
        }}
      >
        <section className={styles.modal_content}>
          <span className={styles.modal_content_icon + ' icon-advertencia'} />
          {message}
        </section>
      </Modal>
    )
  }

  render() {
    if (this.props.error && this.props.error.codigo !== '') {
      if (this.props.error.codigo === BRAND_ERROR) {
        this.modal = (
          <Modal
            closeModalId="ingreso_modal_close_button"
            isVisible={true}
            submitModal={this.modalBrandClickHandler}
            closeModal={this.modalBrandCloseHandler}
            btnModalCloseIsVisible={true}
            buttonProps={{
              buttonId: 'ingreso_modal_button',
              submitButtonText: 'Continuar',
              type: 'link',
              //href: i18next.t('urls.modal.brand')
            }}
          >
            <section className={styles.modal_content}>
              <span className={styles.modal_content_icon + ' icon-advertencia'} />
              <p className={styles.modal_content_text}>
                {i18next.t('ingreso.modal.brandText')}{' '}
              </p>
            </section>
          </Modal>);
      } else {
        this.modal = (
          <Modal
            closeModalId="ingreso_modal_close_button"
            isVisible={true}
            submitModal={this.modalClickHandler}
            closeModal={this.modalClickHandler}
            btnModalCloseIsVisible={true}
            buttonProps={{
              buttonId: 'ingreso_modal_button',
              submitButtonIcon: 'icon-whatsapp',
              submitButtonText: i18next.t('ingreso.modal.submitButton'),
              type: 'link',
              href: this.whatsappLink
            }}
          >
            <section className={styles.modal_content}>
              <span className={styles.modal_content_icon + ' icon-advertencia'} />
              <p className={styles.modal_content_text}>
                {i18next.t('ingreso.modal.text')}{' '}
                <br /><br />
                {i18next.t('ingreso.modal.text2')}{' '}
                <strong className="strong">
                  {i18next.t('ingreso.modal.textStrong')}
                </strong>
              </p>
            </section>
          </Modal>
        );
      }
    } else if (this.props.errorSpareParts && this.props.errorSpareParts.showModal) {
      let message = undefined
      let messageTxt = i18next.t('spareParts.modal.submitButton')
      let buttonExit = true
      if (this.props.errorSpareParts.noData) {
        message = (
          <p className={styles.modal_content_text}>
            {i18next.t('spareParts.modal.noFound1')}
            <br />
            {i18next.t('spareParts.modal.noFound2')}
          </p>
        )
      } else if (this.props.errorSpareParts.trackingVoided) {
        buttonExit = false
        message = (
          <p className={styles.modal_content_text}>
            {i18next.t('spareParts.modal.trackingNumberVoided')}
          </p>
        )
        messageTxt = i18next.t('spareParts.modal.goBack')
      } else {
        message = (
          <p className={styles.modal_content_text}>
            {i18next.t('spareParts.modal.error1')}
            <br />
            {i18next.t('spareParts.modal.error2')}
          </p>
        )
      }
      this.modal = this.createSparePartErrorModal(message, messageTxt, buttonExit);
    } else {
      this.modal = null;
    }

    let title = undefined;
    let subtitle = undefined;
    if (this.isFromQR) {
      title = i18next.t('ingreso.form.titleQr')
    } else {
      title = i18next.t('ingreso.form.title')
      subtitle = i18next.t('ingreso.form.subtitle')
    }

    return (
      <>
        {this.props.isFetching ? <Loading /> : null}
        {this.modal}
        <section className={styles.ingreso_container}>
          <section className={styles.main_container}>
            <Form
              placeholder={i18next.t('ingreso.form.placeholder')}
              label={i18next.t('ingreso.form.label')}
              guideNumberPlaceHolder={i18next.t('ingreso.form.guideNumberPlaceHolder')}
              guideNumberLabel={i18next.t('ingreso.form.guideNumberLabel')}
              insuredButtonText={i18next.t('ingreso.form.insuredButtonText')}
              thirdText={i18next.t('ingreso.form.thirdText')}
              thirdButtonText={i18next.t('ingreso.form.thirdButtonText')}
              thirdButtonLink={i18next.t('ingreso.form.thirdButtonLink')}
              title={title}
              subtitle={subtitle}
              numSiniestro={this.state.numSiniestro}
              numGuia={this.state.numGuia}
              errorMessage={this.state.errorMessage}
              errorMessageGuideNumber={this.state.guideNumberErrorMessage}
              onInsuredSubmit={this.formSubmitHandler}
              onWorkshopSubmit={this.formSubmitHandlerWorkshop}
              onThirdSubmit={this.thirdSubmitHandler}
              onInputBlur={this.inputBlurHandler}
              onInputFocus={this.inputFocusHandler}
              onInputChange={this.inputChangeHandler}
              onInputChangeGuideNumber={this.inputChangeHandlerGuideNumber}
              isValid={this.state.formIsValid}
              isValidGuideNumber={this.state.formIsValidGuideNumber}
              showError={this.state.showFormError}
              showErrorGuideNumber={this.state.showFormErrorGuideNumber}
              inputId="ingreso_form_input"
              insuredButtonId="ingreso_form_asegurado_button"
              thirdButtonId="ingreso_form_tercero_button"
              switchId="ingreso_form_switch"
              ref={this.inputRef}
              tooltipMessage={i18next.t('ingreso.form.tooltipMessage')}
              infoId="ingreso_form_input_info"
              changeReCaptchaStatus={this.changeReCaptchaStatusHandler}
              recaptchaStyle={
                !this.state.reCaptchaIsValid &&
                  this.state.showFormError &&
                  this.state.errorMessage ===
                  i18next.t('ingreso.form.errorMessageReCaptcha')
                  ? styles.recaptcha + ' ' + styles.recaptcha__error
                  : styles.recaptcha
              }
              isFromQR={this.isFromQR}
            />
          </section>
        </section>
      </>
    );
  }
}

/* 
 * state.dataPrecargada solo se utiliza cuando se llega desde un cambio de contexto aliro/liberty
 * para el resto de los casos el siniestro llega desde el reducer de ingreso (ingreso.reducer.js)
 */
const mapStateToProps = state => {
  return {
    siniestro: state.dataPrecargada.siniestro ? state.dataPrecargada.siniestro : state.siniestro.siniestro,
    error: state.siniestro.error,
    isFetching: state.siniestro.isFetching || state.spareParts.isFetching,
    errorSpareParts: state.spareParts.error,
    timestamp: state.sessionTimestamp
  };
};

const mapDispatchToProps = dispatch => ({
  consultarSiniestro: numeroSiniestro =>
    dispatch(actions.consultarSiniestro(numeroSiniestro)),
  comunicarError: error =>
    dispatch(actions.comunicarError(error)),
  setErrorSpareParts: error =>
    dispatch(actionsSpareParts.setError(error)),
  consultarRepuestos: (numeroSiniestro, guia) =>
    dispatch(actionsSpareParts.consultarRepuestos(numeroSiniestro, guia)),
  whatsappVisibility: isVisible =>
    dispatch(actionsApp.showWhatsappIcons(isVisible)),
  salvarContexto: (timestamp , siniestro) => 
    dispatch(actions.salvarContexto(timestamp , siniestro))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ingreso);
