import axios from "axios";
import history from "../../config/history";
import * as actionsIngreso from "../ingreso/ingreso.actions";
import { ACTION_TYPES } from "./callback.reducer";
import { ACTION_TYPES as ACTION_TYPES_INGRESO }  from "../ingreso/ingreso.reducer";


export const redirectTracking = userToken => {
  return async dispatch => {
    dispatch({ type: ACTION_TYPES.FETCHING_DATA });
    try {
      const portalData = await getPortalDataService(userToken);
      const numSiniestro = portalData.data.claimNumber;
      const timestamp = portalData.data.timestamp;

      if (numSiniestro && timestamp) {

        const response = await actionsIngreso.consultarSiniestroActionService(numSiniestro);
        await actionsIngreso.salvarContextoService(timestamp, response.data.responseBody)
        history.push(`/siniestro/${numSiniestro}?timestamp=${timestamp}`);
      } else {
        history.replace("/");
      }
    } catch (error) {
      console.error(error);
      history.replace("/");
    }
  };
};

export const getPortalDataService = async userToken => {
  const portalDataServiceURL = process.env.REACT_APP_URL_LAMBDA_GET_REGTRACK;
  try {
    return await axios.get(portalDataServiceURL, {
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });
  } catch (error) {
    console.error(error);
  }
};

export const setSiniestro = siniestro => {
  return {
    type: ACTION_TYPES_INGRESO.CONSULTAR_SINIESTRO_SUCCESS,
    siniestro: siniestro
  };
};
