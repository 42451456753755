import React from 'react';
import styles from './backdrop.module.scss';
import PropTypes from 'prop-types';

const backdrop = props => {
  const customStyles = props.isVisible
    ? [styles.backdrop, styles.backdrop_show].join(' ')
    : styles.backdrop;
  return <div onClick={props.onClick} className={customStyles} />;
};

backdrop.propTypes = {
  /** Propiedad que indica si el backdrop se muestra o no. */
  isVisible: PropTypes.bool,
  /** Propiedad que es una función que se acciona cuando se hace click en el componente. */
  onClick: PropTypes.func
};

export default backdrop;
