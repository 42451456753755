export const BRAND_1 = {
    style: {
        "name": "Liberty Seguros",
    },
}

export const BRAND_2 = {
    style: {
        name: "Aliro Seguros",
        imageSrc: "logo-aliro-2.png",
        secondImageSrc: "logo-marca-liberty.png",
        imageWidth: "154px",
        imageHeight: "110px",
        imageHeightQuery: 150,
        imageWidthQuery: 110,
        secondImageWidth: 197,
        secondImageHeight: 65,
        secondImageHeightQuery: 140,
        secondImageWidthQuery: 45,
        header: {
            paddingHeader: "17px, 0px, 17px, 0px",
            background: "#fff",
            width: "100%",
            height: "110px",
            navList: [],
            brandHref: "https://www.aliroseguros.cl",
            brandAlt: "Aliro Seguros"
        },
        subHeader: {
            justifyContent: "space-between",
            background: "#5C88DA",
            backgroundBorderTop: "rgb(19, 61, 144)",
            width: "100%",
            fontSize: "14px",
            fontWeight: 400,
            padding: ".5rem",
            textAling: "center",
            height: "50px",
            tittleHeight: "50px",
            subNavList: [{
                title: "Nosotros",
                link: "#",
                name: `Nosotros`,
                dropDownItems: [{
                    title: "Quienes Somos",
                    link: "https://www.aliroseguros.cl/quienes-somos",
                    name: "Quienes Somos",
                }, {
                    title: "Términos de Uso",
                    link: "https://www.aliroseguros.cl/terminos-de-uso",
                    name: "Términos de Uso",
                }],
            },
            {
                title: "Nuestros productos",
                link: "#",
                name: "Nuestros productos",
                dropDownItems: [{
                    title: "Seguro de Auto Light",
                    link: "https://www.aliroseguros.cl/nuestros-productos/seguro-de-auto-light",
                    name: "Seguro de Auto Light",
                },
                /*{
                    title: "Seguro de Auto Plus",
                    link: "https://www.aliroseguros.cl/nuestros-productos/seguro-de-auto-plus",
                    name: "Seguro de Auto Plus",
                }*/],
            },
            {
                title: "Denuncio Web",
                link: "https://denuncio.aliroseguros.cl",
                name: `Denuncio Web`,
                dropDownItems: [],
            },
            {
                title: "Centro de Siniestros",
                link: "https://www.aliroseguros.cl/centro-de-siniestro-de-vehiculo",
                name: `Centro de Siniestros`,
                dropDownItems: [],
            },
            {
                title: "Pago en Línea",
                link: "/pagoenlinea",
                name: `Pago en Línea`,
                dropDownItems: [],
            },            
            {
                title: "Contáctanos",
                link: "https://www.aliroseguros.cl/contactanos",
                name: "Contáctanos",
                dropDownItems: [],
            }],
        },
        lateralNavBar: {
            visible: false,
            backgroundIconResponsive: "#fff",
            backgroundIconFillResponsive: "#4F70AD",
            backgroundColor: "#5C88DA",
            iconsColor: "#fff",
            fontColor: "#fff",
            escribenos: {
                href: "#",
                visible: true,
                title: "Test"
            },
            llamanos: {
                href: "#",
                visible: true,
                title: "Test"
            },
            buscanos: {
                href: "#",
                visible: true,
                title: "Test"
            },
        },
        footer: {
            socialAreaVisible: false,
            href: "https://www.aliroseguros.cl",
            imageGrupoLiberty: "grupo-liberty.svg",
            imageDefensoriaSrc: "logo-defensoriaAliro.png",
            imageSrc: "logo-blanco-aliro.png",
            imgAlt: "Aliro Seguros",
            backgroundColor: "#00C7B1",
            padding: "0px 0px 0px 0px ",
            visible: true,
            height: "",
            footerLogoDisplay: "contents",
            hideSocialIcons: true,
            footerDefensoriaLogo: {
                paddingBottom: 0,
            },
            footerListStyle: {
                width: "40%",
                padding: "9px 0 7px 0",
            },
            footerItems: [{
                fontWeight: 500,
                letterSpacing: 0,
                fontSize: 14,
                lineHeight: "unset",
                fontColor: "#fff",
                title: "Nosotros",
                link: "https://www.aliroseguros.cl/quienes-somos",
                name: "Nosotros",
                downList: [{
                    fontColor: "#fff",
                    fontWeight: "400",
                    href: "https://www.aliroseguros.cl/quienes-somos",
                    fontSize: 14.3,
                    textAling: "center",
                    textDecoration: "none",
                    title: "¿Quiénes Somos?",
                    name: "¿Quiénes Somos?",
                    fontFamily: "roboto, sans-serif",
                },
                {
                    fontColor: "#fff",
                    fontWeight: "400",
                    href: "https://www.aliroseguros.cl/terminos-de-uso",
                    textAling: "center",
                    fontSize: 14.3,
                    textDecoration: "none",
                    title: "Terminos de Uso",
                    name: "Terminos de Uso",
                    fontFamily: "roboto, sans-serif",
                }]
            },
            {
                fontWeight: 400,
                letterSpacing: "0.2px",
                fontSize: 14.3,
                lineHeight: "unset",
                fontColor: "#fff",
                title: "Nuestros productos",
                fontFamily: "roboto, sans-serif",
                link: "#",
                textAling: "center",
                name: "Nuestros productos",
                downList: [{
                    fontColor: "#fff",
                    href: "https://www.aliroseguros.cl/nuestros-productos/seguro-de-auto-light",
                    fontSize: 14.3,
                    fontWeight: "400",
                    textAling: "center",
                    title: "Seguro de Auto Light",
                    textDecoration: "none",
                    name: "Seguro de Auto Light",
                    fontFamily: "roboto, sans-serif",
                }]
            }, {
                fontWeight: 400,
                letterSpacing: "0.2px",
                fontSize: 14.3,
                lineHeight: "unset",
                fontColor: "#fff",
                title: "Denuncio Web",
                fontFamily: "roboto, sans-serif",
                link: "https://denuncio.aliroseguros.cl",
                textAling: "center",
                name: "Denuncio Web",
                downList: []
            }, {
                fontWeight: 400,
                letterSpacing: "0.2px",
                fontSize: 14.3,
                lineHeight: "unset",
                fontColor: "#fff",
                textAling: "center",
                fontFamily: "roboto, sans-serif",
                title: "Seguimiento de Siniestros",
                link: "https://seguimientosiniestro.aliroseguros.cl",
                name: "Seguimiento de Siniestros",
                downList: []
            },
            {
                fontWeight: 400,
                letterSpacing: "0.2px",
                fontSize: 14.3,
                lineHeight: "unset",
                fontFamily: "roboto, sans-serif",
                fontColor: "#fff",
                textAling: "center",
                title: "Servicio al Cliente",
                link: "#",
                name: "Servicio al Cliente",
                downList: [
                    {
                        fontColor: "#fff",
                        fontWeight: "400",
                        href: "https://www.aliroseguros.cl/contactanos",
                        fontSize: 14.3,
                        textAling: "center",
                        textDecoration: "none",
                        title: "Contactanos",
                        name: "Contactanos",
                        fontFamily: "roboto, sans-serif",
                    },
                    {
                        fontColor: "#fff",
                        fontWeight: "400",
                        href: "/pagoenlinea",
                        textAling: "center",
                        fontSize: 14.3,
                        textDecoration: "none",
                        title: "Pago en Línea",
                        name: "Pago en Línea",
                        fontFamily: "roboto, sans-serif",
                    }                    
                ]
            }]
        },
        contactArea: {
            backgroundColor: "rgb(92, 136, 218)",
            fontColor: "#fff",
            visible: false,
        }
    },
}

export default BRAND_2;