import React, { Component } from 'react';
import ReactTooltip from "react-tooltip";
import styles from './input.module.scss';
import PropTypes from 'prop-types';

class Input extends Component {
  tooltip;
  styleTooltipOpen = styles.tooltip__open;
  constructor(props) {
    super(props);
    this.props = props;
    this.inputRef = React.createRef();
    this.tooltip = React.createRef();
  }

  static propTypes = {
    /** Propiedad que indica los estilos que tendrá el componente, estos estilos se los pasa el componente padre. */
    styles: PropTypes.string,
    /** Propiedad que indica si se muestra el error debajo del input. */
    showError: PropTypes.bool,
    /** Propiedad que indica el id del input. */
    id: PropTypes.string,
    /** Propiedad que indica el texto a mostrar en el placeholder del input. */
    placeholder: PropTypes.string,
    /** Propiedad que es una función que permite accionarse cuando se deja de hacer foco al input. */
    onBlur: PropTypes.func,
    /** Propiedad que es una función que permite accionarse cuando se hace foco al input. */
    onFocus: PropTypes.func,
    /** Propiedad que es una función que permite accionarse cuando se hace un cambio de valor al input. */
    onChange: PropTypes.func,
    /** Propiedad que indica el minimo valor permitido por la caja de texto para que sea válido. */
    minLength: PropTypes.number,
    /** Propiedad que indica el máximo valor permitido por la caja de texto para que sea válido. */
    maxLength: PropTypes.number,
    /** Propiedad que indica el valor que tendrá el input. */
    value: PropTypes.string,
    /** Propiedad que indica el id que tendrá el tooltip. */
    infoId: PropTypes.string,
    /** Propiedad que indica el texto que tendrá el tooltip. */
    tooltipMessage: PropTypes.string,
    /** Propiedad que indica el texto que tendrá el error debajo del input. */
    errorMessage: PropTypes.string
  };

  /*componentDidMount() {
    window.addEventListener('click', event => {
      if (
        typeof (event.target.className) === 'string' &&
        event.target.className.indexOf('icon-info') === -1
      ) {
        if (this.tooltip.current) {
          this.tooltip.current.classList.remove(this.styleTooltipOpen);
        }
      }
    });
  }*/

  onlyNumbers = e => {
    this.inputRef.current.value = e.target.value.replace(/\D/g, '');
  };

  toggleTooltip = () => {
    if (this.tooltip.current) {
      this.tooltip.current.classList.toggle(this.styleTooltipOpen);
    }
  };

  render() {
    this.estilosPersonales = styles.custom_input;
    if (this.props.styles) {
      this.estilosPersonales = [this.estilosPersonales, this.props.styles].join(
        ' '
      );
    }
    if (this.props.showError) {
      this.estilosPersonales = [
        this.estilosPersonales,
        styles.custom_input_error
      ].join(' ');
    }

    let tooltip = undefined;
    if (this.props.tooltipMessage) {
      tooltip = (
        /*<div className={styles.div_tooltip}>
          <section
            className={styles.input_container_info}
            onClick={() => this.toggleTooltip()}
            id={this.props.infoId}
          >
            <span className={styles.icon + ' icon-info'} />
          </section>
          {<article className={styles.tooltip} ref={this.tooltip}>
            <p>{this.props.tooltipMessage}</p>
          </article>}
        </div>*/
        <div className={styles.div_tooltip}>
          <section
            className={styles.input_container_info}
          >
            <span className={styles.icon + ' icon-info'} data-tip data-for="infoTip" />
          </section>
          <ReactTooltip className={styles.tooltip_custom} id="infoTip" place="right" arrowColor="#1A1446" type="#1A1446" effect="solid">
            {this.props.tooltipMessage}
          </ReactTooltip>
        </div>
      )
    }

    return (
      <section className={this.estilosPersonales}>
        {this.props.labelTitle &&
          <label className={styles.input_label} >
            {this.props.labelTitle}
            {this.props.required &&
              <span className={styles.input_required}> * </span>
            }
          </label>
        }
        <div className={styles.input_container}>
          <input
            id={this.props.id}
            className={styles.input}
            placeholder={this.props.placeholder}
            onBlur={this.props.onBlur}
            onFocus={this.props.onFocus}
            onChange={event => this.props.onChange(event)}
            minLength={this.props.minLength}
            maxLength={this.props.maxLength}
            value={this.props.value}
            ref={this.inputRef}
          />
          {tooltip}
        </div>
        <label className={styles.error}>{this.props.errorMessage}</label>
      </section>
    );
  }
}

export default Input;


