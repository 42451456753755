import React, { Component } from 'react';
import Steps from './steps/steps';
import i18next from '../../../config/i18next';
import CONSTANTS from '../../../config/constants';
import styles from './trackingInfo.module.scss';
import PartialTracking from './generateTracking/partialTracking.model';
import ExpressTracking from './generateTracking/expressTracking.model';
import TheftTracking from './generateTracking/theftTracking.model';
import TotalTracking from './generateTracking/totalTracking.model';
import PropTypes from 'prop-types';

const carSVG = require('../../../../assets/icons/svg/car.svg');

class TrackingInfo extends Component {
	constructor(props) {
		console.log('[trackingInfo.js][constructor] ');
		super(props);
		this.props = props;
		this.stepsRef = React.createRef();
		this.stepsInfoRef = React.createRef();
		this.barRef = React.createRef();
		this.barAdvanceRef = React.createRef();
		this.advance = '';

		switch (this.props.siniestro.categoriaSiniestro) {
			case CONSTANTS.CLAIM_TYPE.PARTIAL:
				this.steps = new PartialTracking(this.props.siniestro).steps;
				break;
			case CONSTANTS.CLAIM_TYPE.EXPRESS:
				this.steps = new ExpressTracking(this.props.siniestro).steps;
				break;
			case CONSTANTS.CLAIM_TYPE.THEFT:
				this.steps = new TheftTracking(this.props.siniestro).steps;
				break;
			case CONSTANTS.CLAIM_TYPE.TOTAL:
				this.steps = new TotalTracking(this.props.siniestro).steps;
				break;
			default:
				this.steps = new PartialTracking(this.props.siniestro).steps;
				break;
		}
	}

	static propTypes = {
		/** Propiedad que tiene todos los datos del siniestro. */
		siniestro: PropTypes.object,
	};

	/**
	 * fFuncion ejecutada al iniciar el componente.
	 */
	componentDidMount() {
		console.log('componentDidMount');
		this.updateProgressBarDimensions();
		window.addEventListener('resize', this.updateProgressBarDimensions);
	}

	/**
	 * Actualiza las dimensiones de la barra de la barra de progreso en versión mobile.
	 */
	updateProgressBarDimensions = () => {
		if (this.checkReferences()) {
			const totalPasos = this.steps.length;
			const pasoActual = this.props.siniestro.paso;
			if (window.innerWidth < CONSTANTS.WINDOW_SIZES.TABLET) {
				this.barRef.current.style.width =
					'calc(' +
					this.stepsRef.current.stepsRef.current.childNodes[
						this.stepsRef.current.stepsRef.current.childNodes.length - 1
					].offsetTop +
					'px - ' +
					this.stepsRef.current.stepsRef.current.childNodes[0].offsetTop +
					'px)';

				this.barAdvanceRef.current.style.width =
					'calc(' +
					this.stepsRef.current.stepsRef.current.childNodes[pasoActual - 1]
						.offsetTop +
					'px - ' +
					this.stepsInfoRef.current.offsetTop +
					'px)';
			} else {
				this.barRef.current.removeAttribute('style');
				const avance = (100 / (totalPasos - 1)) * (pasoActual - 1);
				this.barAdvanceRef.current.style.width = avance + '%';
			}
		}
	};

	/**
	 * Verifica si las referencias funcionan.
	 */
	checkReferences = () => {
		console.log('[trackingInfo.js][checkReferences] Iniciando el método');
		return (
			this.stepsRef.current &&
			this.stepsInfoRef &&
			this.barRef.current &&
			this.barAdvanceRef.current
		);
	};

	formatLastUpdate = data => {
		let fullDate = new Date(data);

		let fecha = fullDate.toLocaleDateString('es-CL');

		return (
			fecha.replace(/-/g, '/') +
			' - ' +
			('0' + fullDate.getHours()).slice(-2) + ':'
			+ ('0' + fullDate.getMinutes()).slice(-2)
		);
	};

	render() {
		console.log('[trackingInfo.js][render] Iniciando el método');
		return (
			<section className={styles.tracking}>
				<h2 className={styles.tracking_title}>
					{i18next.t('tracking.trackingInfo.title')}
				</h2>
				<article className={styles.update_at_container}>
					<span className={styles.update_at_container_title}>
						{i18next.t('tracking.trackingInfo.lastUpdateTitle')}
					</span>
					<span className={styles.update_at_container_value}>
						{this.formatLastUpdate(
							this.props.siniestro.fechaUltimaActualizacion
						) || i18next.t('tracking.mainInfo.withoutInfo')}
					</span>
				</article>
				<section className={styles.tracking_info}>
					<section className={styles.tracking_info_progress}>
						<div
							className={styles.tracking_info_progress_bar}
							ref={this.barRef}
						>
							<div
								className={styles.tracking_info_progress_bar_completed}
								ref={this.barAdvanceRef}
							>
								<img
									src={carSVG}
									className={styles.tracking_info_progress_bar_completed_item}
									alt="Auto"
								/>
							</div>
						</div>
					</section>
					<section
						className={styles.tracking_info_steps}
						ref={this.stepsInfoRef}
					>
						<Steps
							ref={this.stepsRef}
							steps={this.steps}
							claimCategory={this.props.siniestro.categoriaSiniestro}
							claimStatus={this.props.siniestro.codigoEstadoSiniestro}
							claimNumber={this.props.siniestro.numeroSiniestro}
						/>
					</section>
				</section>
			</section>
		);
	}
}

export default TrackingInfo;
