import i18next from '../../../../../../../config/i18next';
import CONSTANTS from '../../../../../../../config/constants';

class SubStepModel {
  constructor(status = null, step = null, substep = null, type = null) {
    this.status = status;
    this.step = step;
    this.substep = substep;
    this.setType(type);
    this.setData();
  }
  label = null;
  status = null;
  date = null;
  step = null;
  subStepString = null;

  setType(categoriaSiniestro) {
    switch (categoriaSiniestro) {
      case CONSTANTS.CLAIM_TYPE.PARTIAL:
        this.type = 'partial';
        break;
      case CONSTANTS.CLAIM_TYPE.EXPRESS:
        this.type = 'express';
        break;
      case CONSTANTS.CLAIM_TYPE.THEFT:
        this.type = 'theft';
        break;
      case CONSTANTS.CLAIM_TYPE.TOTAL:
        this.type = 'total';
        break;
      case CONSTANTS.CLAIM_TYPE.THIRD:
        this.type = '';
        break;
      default:
        this.type = 'partial';
        break;
    }
  }

  setData() {
    switch (this.substep) {
      case 1:
        this.subStepString = 'preAnalysis';
        break;
      case 2:
        this.subStepString = 'adjustment';
        break;
      case 3:
        this.subStepString = 'laborOrder';
        break;
      case 4:
        this.subStepString = 'purchaseOrder';
        break;
      default:
        break;
    }

    this.label = i18next.t(
      'tracking.trackingInfo.' +
      this.step.toString() +
      '.' +
      this.type +
      '.substeps.' +
      this.subStepString +
      '.' +
      this.status +
      '.label'
    );
  }

  setStatus(status) {
    this.status = status;
    this.setData();
  }

  setDate(date) {
    if (date) {
      this.status = 'approved';
      this.date = date;
      this.setData();
    }
  }
}

export default SubStepModel;
