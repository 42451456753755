import axios from 'axios';
import { ACTION_TYPES } from './spareParts.reducer';
import history from '../../config/history';
import {URL_SPARE_PART} from '../../config/constants';




export const consultarRepuestosActionService = async (numeroSiniestro, numeroGuia) => {
  return axios.get(URL_SPARE_PART + '/repuestos?noticeNumber=' + numeroSiniestro + '&guideNumber=' + numeroGuia);
};

export const postSaveSpareParts = async (body) => {
  return axios.post(URL_SPARE_PART + '/repuestos', body);
};
export const setSparePartsData = data => {
  return {
    type: ACTION_TYPES.CONSULTAR_REPUESTOS_SUCCESS ,
    request: data
  };
};

export const setError = error => {
  return {
    type: ACTION_TYPES.CONSULTAR_REPUESTOS_FAILURE,
    error: error
  }
}

export const setErrorSpareParts = result => {
  return {
    type: ACTION_TYPES.GUARDAR_RECEPCION_FAILURE,
    result: result
  }
}

export const setSaveSuccefull = (result) => {
  return {
    type: ACTION_TYPES.GUARDAR_RECEPCION_SUCESS,
    result: result
  }
}

export const cleanResponsePost = (result) => {
  return {
    type: ACTION_TYPES.CLEAN_ERROR_SAVE,
    result: undefined
  }
}

export const consultarRepuestos = (numeroSiniestro, numeroGuia) => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.CONSULTAR_REPUESTOS_REQUEST });
    console.log("findSpareParts NS: ", numeroSiniestro , " NG: " , numeroGuia);
    //dispatch(setError({
      //message: "Error al obtener información",
      //showModal: true,
      //noData: false
    //}))
    
    consultarRepuestosActionService(numeroSiniestro, numeroGuia)
      .then(response => {
        console.log("findSpareParts response: " , response)
        if (response && response.status === 200 && response.data.status === 200) {
          let data = response.data
          let trackingStatus = data.trackingStatus
          if (trackingStatus !== 'A') {
            if (data.spareParts && data.spareParts.length > 0) {
              dispatch(setSparePartsData({
                numeroSiniestro: numeroSiniestro,
                numeroGuia: numeroGuia,
                spareParts: data.spareParts,
                workshopName: data.workshopName,
                provider: data.provider,
                receptionistName: data.receptionistName,
                receptionistIdentifier: data.receptionistIdentifier,
                vehicleInWorkshop: data.vehicleInWorkshop,
                trackingStatus: data.trackingStatus,
                orderStatus: data.orderStatus
              }));
              setError({ showModal: false, message: '' });
              history.push(`/repuestos/${numeroSiniestro}/${numeroGuia}`);
            } else {
              dispatch(setError({
                message: "Sin resultado",
                showModal: true,
                noData: true
              }))
            }
          } else {
            // guia anulada 
              dispatch(setError({
                message: "Estado de la guía no es valido para recepcionar",
                showModal: true,
                noData: false,
                trackingVoided: true 
              }))
          }
        } else {
          console.log("findSpareParts error response: ", response)
          dispatch(setError({
            message: "Error al obtener información",
            showModal: true,
            noData: false
          }))
        }
      })
      .catch( error => {
        console.log("findSpareParts error: ", error)
        dispatch(setError({
          message: "Error al obtener información",
          showModal: true,
          noData: false
        }))
      });
  };
};


export const saveSpareParts = (data, spareParts) => {
  console.log("noticeNumber: ", data.noticeNumber)
  console.log("guideNumber: ", data.guideNumber)
  console.log("nameReceptionist: ", data.nameReceptionist)
  console.log("identifierReceptionist: ", data.identifierReceptionist)
  console.log("vehicleInWorkshop: ", data.vehicleInWorkshop)
  console.log("spareParts: ", spareParts)
  return dispatch => {

    dispatch({ type: ACTION_TYPES.GUARDAR_RECEPCION_REQUEST });
    let body = {
      noticeNumber: data.noticeNumber,
      guideNumber: data.guideNumber,
      receptionistName: data.nameReceptionist,
      receptionistIdentifier: data.identifierReceptionist,
      vehicleInWorkshop: data.vehicleInWorkshop,
      spareParts: spareParts
    }

    //dispatch(setErrorSpareParts({
      //message: "Error al guardar repuestos",
      //saveSuccefull: false
    //}))

    postSaveSpareParts(body)
      .then(response => {
        console.log("SaveSpareParts response: ", response)
        let code = response.data.status
        if (code === 200) {
          dispatch(setSaveSuccefull({
            message: "Guardado correctamente",
            saveSuccefull: true
          }))
        }else {
          console.log("error statusCode != 200")
          dispatch(setErrorSpareParts({
            message: response.data.message,
            saveSuccefull: false
          }))
        }
      })
      .catch( error => {
        console.log("SaveSpareParts error: ", error)
        dispatch(setErrorSpareParts({
          message: "Error al guardar repuestos",
          saveSuccefull: false
        }))
      })
  }
}

export const updateSparePart = (newItem, index) => {
  return {
    type: ACTION_TYPES.UPDATE_SPARE_PARTS,
    newItem: newItem,
    index: index
  }
}

export const setSpareParts = items => {
  return {
    type: ACTION_TYPES.SET_SPARE_PARTS,
    items: items
  }
}
