import React, { Component } from 'react';
import styles from './switchSelector.module.scss';
import PropTypes from 'prop-types';




class SwitchSelector extends Component {

  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    let selected = 0;
    if (this.props.initialSelection) {
      selected = this.props.initialSelection
    }
    this.state = {
      selected: selected,
      previewSelected: -1
    }
  }

  static propTypes = {
    /** Propiedad que indica los estilos que tendrá el componente, estos estilos se los pasa el componente padre. */
    styles: PropTypes.object,
    /** Propiedad que indica el id que tendrá el switch. */
    id: PropTypes.string,
    /** Propiedad de función que se acciona cuando se cambia el estado del switch. */
    onChange: PropTypes.func,
    /** Propiedad que indica si el switch está seleccionado. */
    initialSelection: PropTypes.number,
    /** Propiedad con un arreglo de objetos, cada objeto debe tener los
    * atributos id y name. El name se muestra y el id es para comparar si esta
    * seleccionado */
    options: PropTypes.array,
  };


  render() {
    let props = this.props;
    if (props.options.length === 0) {
      return <div/>;
    }
    let estilosPersonales = styles.switch_selector;
    if (props.styles) {
      estilosPersonales = [estilosPersonales, props.styles].join(' ');
    }
    let options = props.options.map((option, i) => {
      let onClick = () => {
        this.onChange(option.id, option)
      }
      let style = styles.switch_selector_options_item
      if (this.state.selected === option.id) {
        style += ' ' + styles.switch_selector_options_item_selected
      }
      return (
        <article 
          className={style}
          onClick={onClick}
          key={i}
        >
          <div>
            {option.name}
          </div>
        </article>
      )
    })

    return (
      <label className={estilosPersonales} id={props.id}>
          <span className={styles.slider} />
          <section className={styles.switch_selector_options} >
            {options}
          </section>
      </label>
    );
  }

  onChange(index, option) {
    let prev = this.state.selected;
    console.log("selected", option, " - selected: " , index, " prev: " , prev)
    let diff = index - prev;
    if (this.props.onChange) {
      this.props.onChange(index, option, diff)
    }
    this.setState({
      selected: index,
      previewSelected: prev
    })
  }
}


export default SwitchSelector;
