import PropTypes from 'prop-types';
import React from 'react';
import styles from './button.module.scss';

const verifyAliro = () => {
  let urlName = window.location.hostname;
  if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {
    return true;
  }
  return false;
}

const Button = props => {
  let customStyles = styles.button;
  let button = null;

  switch (props.buttonType) {
    case 'primary_teal':
      customStyles = customStyles + ' ' + styles.button_primary_teal;
      break;
    case 'primary_dark_teal':
      customStyles = customStyles + ' ' + styles.button_primary_dark_teal;
      break;
    case 'primary_yellow':
      if(verifyAliro()){
        customStyles = customStyles + ' ' + styles.button_primary_blue;
      }else{
        customStyles = customStyles + ' ' + styles.button_primary_yellow;
      }
      
      break;
    case 'secondary_artmosphery_white':
      customStyles =
        customStyles + ' ' + styles.button_secondary_artmosphery_white;
      break;

    default:
      break;
  }

  customStyles = props.styles
    ? customStyles + ' ' + props.styles
    : customStyles;

  switch (props.type) {
    case 'button':
      button = (
        <button
          id={props.id}
          className={customStyles}
          onClick={props.onClick}
          disabled={props.disabled}
          type="button"
        >
          {props.children}
          {props.texto}
        </button>
      );
      break;
    case 'link':
      button = (
        <a
          id={props.id}
          className={customStyles}
          onClick={props.onClick}
          disabled={props.disabled}
          href={props.href}
          target={props.noTarget ? null : '_blank'}
          rel="noopener noreferrer"
        >
          {props.children}
          {props.texto}
        </a>
      );
      break;

    default:
      button = (
        <input
          id={props.id}
          className={customStyles}
          onClick={props.onClick}
          disabled={props.disabled}
          type="submit"
          value={props.texto}
        />
      );
      break;
  }

  return button;
};

Button.propTypes = {
  /** Propiedad que indica el tipo de botón que será (para fines de estilos). */
  buttonType: PropTypes.string,
  /** Propiedad que indica los estilos que tendrá el componente, estos estilos se los pasa el componente padre. */
  styles: PropTypes.string,
  /** Propiedad que indica el tipo de botón que será. */
  type: PropTypes.string,
  /** Propiedad que indica id que tendrá el botón. */
  id: PropTypes.string,
  /** Propiedad de función que se accionará cuando se haga click en el botón. */
  onClick: PropTypes.func,
  /** Propiedad que indica si el botón está deshabilitado. */
  disabled: PropTypes.bool,
  /** Propiedad children es el contenido que estará dentro del componente. */
  children: PropTypes.object,
  /** Propiedad que indica el texto que tendrá el botón. */
  texto: PropTypes.string,
  /** Propiedad que indica el link que tendrá el botón. */
  href: PropTypes.string
};

export default Button;
