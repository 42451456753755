export const ACTION_TYPES = {
    PRELOAD_CONTEXT_REQUEST: 'PRELOAD_CONTEXT_REQUEST',
    PRELOAD_CONTEXT_SUCCESS: 'PRELOAD_CONTEXT_SUCCESS',
    CLEAN_PRELOADED_DATA: 'CLEAN_PRELOADED_DATA'
}

const initialState = {
    siniestro: null,
    isFetching:  false,
    error: { codigo: '', mensaje: '' }
  };

export default (state = initialState, action) => {
    switch (action.type){
        case ACTION_TYPES.PRELOAD_CONTEXT_REQUEST:
            return {
                ...state,
                isFetching: true
            };
        case ACTION_TYPES.PRELOAD_CONTEXT_SUCCESS:
            return {
                ...state,
                isFetching: false,
                siniestro: action.siniestro
            };
        case ACTION_TYPES.CLEAN_PRELOADED_DATA:
            return{
                ...state,
                siniestro: null
            }
        default:
            return state;
    }
}