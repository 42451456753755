import i18next from 'i18next';
import es from '../../i18next/es/index';
import es_aliro from '../../i18next/es-aliro/index'

let urlName = window.location.hostname;

if (urlName.indexOf(process.env.REACT_APP_URL_ALIRO) >= 0) {

  i18next.init({
    interpolation: {
      escapeValue: false
    },
    lng: 'es',
    resources: {
      es: {
        translation: {
          app: es_aliro.app.default,
          ingreso: es_aliro.ingreso.default,
          tracking: es_aliro.tracking.default,
          shared: es_aliro.shared.default,
          infoErrorPage: es_aliro.infoErrorPage.default,
          workshopAdmission: es_aliro.workshopAdmission.default,
          urls: es_aliro.urls.default[process.env.NODE_ENV]
        }
      }
    }
  });

} else {

  i18next.init({
    interpolation: {
      escapeValue: false
    },
    lng: 'es',
    resources: {
      es: {
        translation: {
          app: es.app.default,
          ingreso: es.ingreso.default,
          tracking: es.tracking.default,
          shared: es.shared.default,
          infoErrorPage: es.infoErrorPage.default,
          workshopAdmission: es.workshopAdmission.default,
          spareParts: es.spareParts.default,
          urls: es.urls.default[process.env.NODE_ENV]
        }
      }
    }
  });

}

export default i18next;
